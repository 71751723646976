import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  StepLabel,
  Typography,
  useTheme,
} from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import { NewTripDialogStep1Explanation } from "../../constants";

export const NewTripDialogStep1Contents = ({
  activeStep,
  setActiveStep,
  pastOrFutureTrip,
  setPastOrFutureTrip,
  loading,
}) => {
  const theme = useTheme();

  const stepLabel = pastOrFutureTrip
    ? pastOrFutureTrip[0].toUpperCase() + pastOrFutureTrip.slice(1) + " trip"
    : NewTripDialogStep1Explanation;

  return (
    <>
      <StepLabel
        onClick={() => {
          setActiveStep(0);
        }}
        disabled={loading}
      >
        {stepLabel}
      </StepLabel>
      {activeStep === 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              sx={{
                textAlign: "center",
                cursor: "pointer",
                background:
                  pastOrFutureTrip === "past"
                    ? theme.palette.grey["200"]
                    : "white",
                "&:hover": {
                  background: theme.palette.grey["200"],
                },
              }}
              onClick={() => {
                setActiveStep(1);
                setPastOrFutureTrip("past");
              }}
            >
              <CardMedia>
                <FlightLandIcon sx={{ height: "100px", width: "auto" }} />
              </CardMedia>
              <CardContent>
                <Typography>Past trip</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{
                textAlign: "center",
                cursor: "pointer",
                background:
                  pastOrFutureTrip === "future"
                    ? theme.palette.grey["200"]
                    : "white",
                "&:hover": {
                  background: theme.palette.grey["200"],
                },
              }}
              onClick={() => {
                setActiveStep(2);
                setPastOrFutureTrip("future");
              }}
            >
              <CardMedia>
                <FlightTakeoffIcon sx={{ height: "100px", width: "auto" }} />
              </CardMedia>
              <CardContent>
                <Typography>Future trip</Typography>
              </CardContent>
            </Card>
            <Card></Card>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
