import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Markdown from "react-markdown";
import { Paper } from "@mui/material";
import { Loader } from "./";

import copyrightpolicy from "../terms/copyrightpolicy.md";
import privacypolicy from "../terms/privacypolicy.md";
import termsofuse from "../terms/termsofuse.md";

export const Terms = () => {
  const location = useLocation();

  const [text, setText] = useState(null);

  useEffect(() => {
    const termsMap = {
      copyrightpolicy: copyrightpolicy,
      privacypolicy: privacypolicy,
      termsofuse: termsofuse,
    };
    const file = termsMap[location.pathname.substring(1)];

    fetch(file)
      .then((response) => response.text())
      .then((text) => {
        setText(text);
      });
  }, [location.pathname]);

  return (
    <>
      {text ? (
        <Paper sx={{ textAlign: "left" }} elevation={0}>
          <Markdown>{text}</Markdown>
          <br />
          <br />
        </Paper>
      ) : (
        <Loader />
      )}
    </>
  );
};
