import { TextField, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export const CollaboratorAutocomplete = ({
  currentUserFollowing,
  addCollaboratorEmail,
  setAddCollaboratorEmail,
  label,
  styleOverrides = {},
}) => {
  return (
    <Autocomplete
      id="collaborator-autocomplete"
      size="small"
      options={
        (currentUserFollowing.response &&
          currentUserFollowing.response.map((profile) => profile.email)) ||
        []
      }
      freeSolo
      renderTags={(value, getTagProps) =>
        value &&
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            style: { textTransform: "lowercase" },
          }}
          fullWidth
        />
      )}
      onInputChange={(event, newInputValue) => {
        setAddCollaboratorEmail(newInputValue);
      }}
      inputValue={addCollaboratorEmail}
      sx={{ ...styleOverrides }}
      // TODO investigate listening for reson === selectOption and
      // triggering handleAddCollaborator
      // so doesn't require clicking add button
      // onClose={(event, reason) => {}}
    />
  );
};
