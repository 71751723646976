import { DialogTitle, Grid } from "@mui/material";
import { CloseModal } from "./";

export const TextEditorDialogTitle = ({ title, handleClose, loading }) => {
  return (
    <Grid
      container
      sx={{
        alignItems: "center",
      }}
    >
      <Grid item>
        <DialogTitle>{title} </DialogTitle>
      </Grid>
      {handleClose ? (
        <CloseModal
          handleClose={() => {
            handleClose();
          }}
          loading={loading}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};
