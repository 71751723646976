import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { fetchEventsForTrip, deleteEvent } from "../../utils/event_utils";

export const DeleteEventButton = ({
  editable,
  eventClicked,
  handleClose,
  loading,
  setEvents,
  setLoading,
  tripId,
}) => {
  const confirm = useConfirm();

  return (
    <Button
      aria-label="delete"
      onClick={() => {
        confirm({
          title: "Confirm",
          description: "Deleting an event is permanent!",
          confirmationButtonProps: { variant: "contained" },
        })
          .then(() => {
            setLoading(true);
            deleteEvent({
              id: eventClicked.id,
              tripId: tripId,
            }).then((events) => {
              fetchEventsForTrip({
                tripId: tripId,
                callee: "edit",
              }).then((events) => {
                setEvents(events);
                setLoading(false);
              });
              handleClose();
            });
          })
          .catch(() => {});
      }}
      disabled={loading || !editable ? true : false}
    >
      Delete
    </Button>
  );
};
