import { Box, ToggleButton, Tooltip } from "@mui/material";
import { ShowFavoriteEventsTooltip } from "../../constants";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export const FavoritesToggle = ({
  showFavorites,
  setShowFavorites,
  styleOverrides,
  disabled = false,
}) => {
  return (
    <Box sx={{ ...styleOverrides }}>
      <Tooltip title={ShowFavoriteEventsTooltip}>
        <span>
          <ToggleButton
            aria-label="Show favorites"
            onClick={() => {
              setShowFavorites(!showFavorites);
            }}
            selected={showFavorites}
            size="small"
            label="Show favorites"
            value={showFavorites}
            disabled={disabled}
          >
            {showFavorites ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </ToggleButton>
        </span>
      </Tooltip>
    </Box>
  );
};
