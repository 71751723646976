import { IconButton, Snackbar, Tooltip } from "@mui/material";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const CopyToClipboardButton = ({
  url,
  icon,
  confirmMessage,
  styles,
  tooltipTitle,
  size,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(true);
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <Tooltip title={tooltipTitle || ""}>
        <IconButton
          aria-label="copy"
          onClick={handleClick}
          sx={styles}
          size={size || ""}
        >
          {icon ? icon : <ContentCopyIcon />}
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={confirmMessage ? confirmMessage : "Copied to clipboard!"}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </>
  );
};
