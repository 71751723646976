import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import {
  gleanSuggestedInfoFromValidFiles,
  validateAcceptedFiles,
} from "../../utils/storage_utils";
import { dropzone, thumbsContainer, thumb, thumbInner, img } from "./styles";

// https://react-dropzone.js.org/#!/Previews

export const Dropzone = ({
  allowedFileInfo,
  uploadedFiles,
  setUploadedFiles,
  setAlerts,
  loading,
  explanation,
  label,
  maxFiles = 15,
  paths,
  setPaths,
  location,
  setLocation,
  setStartDate,
  setEndDate,
  setLat,
  setLng,
}) => {
  const { fileExtensions } = allowedFileInfo;
  const [fileLimit, setFileLimit] = useState(false);
  const disableInput = loading || fileLimit ? true : false;

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;

        let { validFiles, alerts } = await validateAcceptedFiles({
          allowedFileInfo,
          uploaded,
          acceptedFiles,
        });

        if (validFiles?.length) {
          const suggestedInfo = await gleanSuggestedInfoFromValidFiles({
            validFiles,
          });

          if (location === "" && suggestedInfo) {
            suggestedInfo.location && setLocation(suggestedInfo.location);
            suggestedInfo.startDate && setStartDate(suggestedInfo.startDate);
            suggestedInfo.endDate && setEndDate(suggestedInfo.endDate);
            suggestedInfo.location && setLocation(suggestedInfo.location);
            suggestedInfo.lat && setLat(suggestedInfo.lat);
            suggestedInfo.lng && setLng(suggestedInfo.lng);
          }
        }

        validFiles?.some(({ file }) => {
          setAlerts(null);
          uploaded.push(file);

          setPaths(
            validFiles.map(({ file }) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );

          if (uploaded.length === maxFiles) setFileLimit(true);
          // alert logic should match upload button
          if (uploaded.length > maxFiles) {
            alert = {
              message: `You can only add a maximum of ${maxFiles} files`,
              severity: "error",
            };
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        });
        if (!limitExceeded) setUploadedFiles(uploaded);

        if (alerts) setAlerts(alerts);
      } catch (e) {
        // console.log({ e });
        setAlerts([
          {
            severity: "error",
            message: "Something went wrong. Try again.",
          },
        ]);
      }
    },
    [setPaths, setFileLimit, setAlerts, uploadedFiles]
  );

  const handleDelete = (index) => {
    let uploadedFilesCopy = [...uploadedFiles];
    uploadedFilesCopy.splice(index, 1);
    setUploadedFiles(uploadedFilesCopy);
    if (alert) setAlerts(null);
    if (uploadedFilesCopy.length === 0) {
      setLocation(null);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: disableInput,
  });

  const thumbs = uploadedFiles.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
      <IconButton
        aria-label="delete"
        onClick={() => handleDelete(index)}
        sx={{
          position: "absolute",
          top: "1px",
          right: "1px",
          height: "15px",
          width: "15px",
        }}
        disabled={loading ? true : false}
      >
        <CloseIcon
          size="small"
          sx={{
            height: "15px",
            width: "15px",
          }}
        />
      </IconButton>
    </div>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })} style={dropzone}>
        <input {...getInputProps()} />
        {label && label}
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
      {explanation && explanation}
    </section>
  );
};
