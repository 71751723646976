import React from "react";
import { Stack } from "@mui/material";

export const EmptyState = ({ children, styleOverrides }) => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ height: "80vh", width: "100%", ...styleOverrides }}
    >
      {children}
    </Stack>
  );
};
