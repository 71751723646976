import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom";
import { localDate, utcDate } from "../utils/date_utils";
import { getComparator, stableSort } from "../utils/trip_utils";
import { TripActionsMenu } from "./";
import { TripsPagination } from "./TripsPagination";

export const TripsTable = ({
  trips,
  actions,
  setTrips,
  columns,
  callee,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}) => {
  const theme = useTheme();
  let navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rows = useMemo(() => {
    // localStorage should only be used for home table
    callee === "home" &&
      localStorage.setItem(
        "trips_table_sort",
        JSON.stringify({ order, orderBy })
      );
    return stableSort(trips, getComparator(order, orderBy));
  }, [order, orderBy, trips, callee]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    rows && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const currentUserId = localStorage.getItem("user_id");
  const currentUserEmail = localStorage.getItem("email");

  return (
    rows && (
      <TableContainer component={Paper} size="small">
        <Table aria-label="simple table" density="compact" stickyHeader>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map(
              (trip, index) =>
                trip && (
                  <TableRow
                    key={`${trip.title}-${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:nth-of-type(odd)": {
                        backgroundColor: theme.palette.action.hover,
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (callee === "home") {
                        navigate(`/trip/${trip.id}`);
                      } else if (callee === "profile" || callee === "explore") {
                        navigate(`/trip/share/${trip.id}`);
                      }
                    }}
                  >
                    {columns.find((x) => x.value === "title") && (
                      <TableCell component="th" scope="row" align="center">
                        {trip.title}
                      </TableCell>
                    )}
                    {columns.find((x) => x.value === "location") && (
                      <TableCell component="th" scope="row" align="center">
                        {trip.location}
                      </TableCell>
                    )}
                    {columns.find((x) => x.value === "permission") && (
                      <TableCell component="th" scope="row" align="center">
                        {trip.is_collaborator ? "Collaborator" : "Owner"}
                      </TableCell>
                    )}

                    {columns.find((x) => x.value === "last modified") && (
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="center"
                      >
                        {/* this date should show users time zone */}
                        {localDate(trip.last_modified_at).format("L LT")}
                      </TableCell>
                    )}

                    {columns.find((x) => x.value === "public views") && (
                      <TableCell component="th" scope="row" align="center">
                        {trip.public_views}
                      </TableCell>
                    )}
                    {columns.find((x) => x.value === "dates") && (
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="center"
                      >
                        {utcDate(trip.start_date).format("M/D/YYYY")} -{" "}
                        {utcDate(trip.end_date).format("M/D/YYYY")}
                      </TableCell>
                    )}
                    {actions && columns.find((x) => x.value === "actions") && (
                      <TableCell component="th" scope="row" align="center">
                        <TripActionsMenu
                          trip={trip}
                          actions={actions}
                          trips={rows}
                          setTrips={setTrips}
                          page={page}
                          setPage={setPage}
                          rowsPerPage={rowsPerPage}
                          currentUserIsOwner={currentUserId === trip.user_id}
                          currentUserIsCollaborator={
                            trip.collaborators?.filter((c) => {
                              return c.email === currentUserEmail || false;
                            }).length > 0
                              ? true
                              : false
                          }
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>

          {rows.length > 6 ? (
            <TableFooter>
              <TableRow>
                <TripsPagination
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  columns={columns}
                  rows={rows}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    )
  );
};

// adapted from here: https://mui.com/material-ui/react-table/#sorting-amp-selecting
const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              headCell.sortable && orderBy === headCell.id ? order : false
            }
            sx={headCell.sx ? headCell.sx : {}}
            align={headCell.align ? headCell.align : "left"}
            padding="normal"
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
