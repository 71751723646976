export const fetchCollaboratorsForTrip = async ({ tripId }) => {
  try {
    const response = await fetch(`/api/collaborators/${tripId}`);
    let collaborators = await response.json();
    return collaborators;
  } catch (e) {
    console.log({ e });
  }
};

export const addCollaborator = async ({
  tripId,
  collaboratorEmail,
  tripTitle,
}) => {
  try {
    const response = await fetch(`/api/collaborators`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tripId,
        collaboratorEmail,
        inviterName:
          localStorage.getItem("first_name") +
          " " +
          localStorage.getItem("last_name"),
        tripTitle,
      }),
    });
    const json = await response.json();
    return json;
  } catch (e) {
    console.log({ e });
  }
};
export const deleteCollaborator = async ({ id }) => {
  try {
    const response = await fetch(`/api/collaborators/delete`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });
    const collaborator = await response.json();
    return collaborator[0];
  } catch (e) {
    console.log({ e });
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
