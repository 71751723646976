import { useEffect, useState, useContext } from "react";
import { AppContext } from "../contexts";
import { Backdrop, useTheme } from "@mui/material";
import { pdfjs, Document, Page } from "react-pdf";
import styled from "styled-components";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { CloseModal } from "./Modals";

export const EventFileDisplay = ({ open, setOpen }) => {
  const { eventFileStr, eventFileExtension } = useContext(AppContext);
  const theme = useTheme();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  return (
    <Backdrop
      sx={{
        color: "#fff",
        backgroundColor: "#fff",
        position: "absolute",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflow: "scroll",
        alignItems: "start",
      }}
      open={open}
    >
      <CloseModal
        handleClose={() => {
          setOpen(false);
        }}
        style={{ zIndex: 1202, backgroundColor: theme.palette.grey["100"] }}
      />
      {eventFileExtension === "pdf" ? (
        <PDFComponent pdfFile={eventFileStr} />
      ) : (
        <img
          alt="attachment"
          src={eventFileStr}
          style={{
            width: "auto",
            height: "100%",
          }}
        />
      )}
    </Backdrop>
  );
};

// https://github.com/wojtekmaj/react-pdf/issues/129#issuecomment-522333713
const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

function PDFComponent({ pdfFile }) {
  const [numPages, setNumPages] = useState(null);
  return (
    <PDFDocumentWrapper key="PDFDocumentWrapper">
      <Document
        file={pdfFile}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page, i) => (
            <Page
              key={`PDFComponent-Page-${i}`}
              pageNumber={page}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ))}
      </Document>
    </PDFDocumentWrapper>
  );
}
