import { useState } from "react";
import { Grid, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { utcDate } from "../utils/date_utils";
import { GoogleMapsAutocompleteWrapper } from "./";
import { EmojiPickerButton } from "./Buttons";
import { EmojiPickerMenu } from "./";
import {
  handleEmojiPickerClose,
  handleEmojiIconButtonOnClick,
  handleEmojiSelection,
} from "../utils/emoji_utils";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

export const TripFields1 = ({
  title,
  setTitle,
  location,
  setLocation,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setLat,
  setLng,
  callee,
  loading,
}) => {
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <TextField
            required
            label="Title"
            fullWidth={true}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            autoFocus={callee === "dialog" && !loading ? true : false}
            disabled={loading}
          />
          {screenIsSmall ? (
            ""
          ) : (
            <EmojiPickerButton
              onClick={({ event }) =>
                handleEmojiIconButtonOnClick({
                  event,
                  setAnchorEl,
                  emojiPickerOpen,
                  setEmojiPickerOpen,
                })
              }
              disabled={loading}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <GoogleMapsAutocompleteWrapper
          location={location}
          setLocation={setLocation}
          setLat={setLat}
          setLng={setLng}
          editable={true}
          required={true}
          label="Add a location"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <DateRangePicker
          localeText={{ start: "Start date", end: "End date" }}
          onChange={(newValue) => {
            newValue[0] && setStartDate(utcDate(newValue[0].$d));
            newValue[1] && setEndDate(utcDate(newValue[1].$d));
          }}
          value={[startDate, endDate]}
          calendars={1}
        />
      </Grid>

      {screenIsSmall ? (
        ""
      ) : (
        <EmojiPickerMenu
          emojiPickerOpen={emojiPickerOpen}
          anchorEl={anchorEl}
          onClose={() =>
            handleEmojiPickerClose({ setAnchorEl, setEmojiPickerOpen })
          }
          onSelection={({ emoji }) =>
            handleEmojiSelection({ emoji, str: title, setStr: setTitle })
          }
          disabled={loading}
        />
      )}
    </Grid>
  );
};
