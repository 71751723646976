import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { closeModalStyles } from "./styles";

export const CloseModal = ({ handleClose, loading, style }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={handleClose}
      // style prop to override default styles
      sx={{ ...closeModalStyles, ...style }}
      disabled={loading ? true : false}
    >
      <CloseIcon />
    </IconButton>
  );
};
