import { Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { utcDate } from "../utils/date_utils";
import { CopyTripEndDateExplanation } from "../constants";

export const TripFieldsCopy1 = ({
  title,
  setTitle,
  startDate,
  setStartDate,
  endDate,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          label="Title"
          fullWidth={true}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          autoFocus={true}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <DatePicker
          slotProps={{
            textField: {
              label: "Start date",
              fullWidth: true,
              required: true,
            },
          }}
          onChange={(newValue) => {
            newValue && setStartDate(utcDate(newValue.$d));
          }}
          value={startDate}
          // https://github.com/mui/mui-x/issues/5190
          maxDate={utcDate(endDate)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="div"
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontWeight: "500",
            fontSize: "0.875rem",
          }}
        >
          {CopyTripEndDateExplanation}
        </Typography>
      </Grid>
    </Grid>
  );
};
