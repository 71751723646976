import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";

export const NoResultsRedirectButton = ({ searchLocation, hasSession }) => {
  let navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(`/home?newtripdialog=true&location=${searchLocation}`);
      }}
      variant="contained"
      size="large"
      endIcon={hasSession ? <AddCircleIcon /> : null}
    >
      {hasSession ? "Create Trip" : "Sign Up to Create Trip"}
    </Button>
  );
};
