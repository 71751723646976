import { useNavigate } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";
import { IconButton, Tooltip } from "@mui/material";
import { NavigateToShareTripTooltip } from "../../constants";

export const ShareTripButton = ({ trip }) => {
  let navigate = useNavigate();

  return (
    <Tooltip title={NavigateToShareTripTooltip}>
      <IconButton
        aria-label="Share"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation();
          navigate(`/trip/share/${trip.id}`);
        }}
      >
        <ShareIcon />
      </IconButton>
    </Tooltip>
  );
};
