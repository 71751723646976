import { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  Stepper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppContext } from "../../contexts";
import { useParams } from "react-router-dom";
import {
  NewTripDialogStep1Contents,
  NewTripDialogStep2Contents,
  NewTripDialogStep3Contents,
  NewTripDialogTitle,
} from "./";
import { SaveTripButton } from "../Buttons";
import { Loader, TripAlerts } from "../";
import { utcDate } from "../../utils/date_utils";
import { RequiredFieldsTooltip } from "../../constants";

export const NewTripDialog = ({ open, handleClose, isFirstTrip }) => {
  const geotaggingEnabled =
    localStorage.getItem("enable_geotagging") === "true";
  const { trips, userId, setTrips, tripLocation } = useContext(AppContext);
  const { trip_id } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState(tripLocation || "");
  // https://github.com/mui/material-ui-pickers/pull/1770
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [lat, setLat] = useState(undefined);
  const [lng, setLng] = useState(undefined);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [soloTraveler, setSoloTraveler] = useState(false);
  const [activeStep, setActiveStep] = useState(geotaggingEnabled ? 0 : 2);
  const [tripAlerts, setTripAlerts] = useState(null);
  const [progress, setProgress] = useState({ value: 0, label: null });
  const [pastOrFutureTrip, setPastOrFutureTrip] = useState(null);
  const [paths, setPaths] = useState([]);
  const [isPublic, setIsPublic] = useState(true);

  // TO DO is this even necessary
  // in what cases is there a trip here?
  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      const trip = trips.find((trips) => trips.id === trip_id);
      if (trip) {
        setTitle(trip.title);
        setStartDate(utcDate(trip.start_date));
        setEndDate(utcDate(trip.end_date));
        setLocation(trip.location);
        setDescription(trip.description);
      }
    }
  }, [trips, trip_id]);

  const reinitializeState = useCallback(
    (pastFutureOverride = null, activeStepOverride = 0) => {
      setTitle("");
      setLocation("");
      setStartDate(undefined);
      setEndDate(undefined);
      setLat(undefined);
      setLng(undefined);
      setDescription("");
      setUploadedFiles([]);
      setProgress({ value: 0, label: null });
      setTripAlerts(null);
      setActiveStep(activeStepOverride);
      setPastOrFutureTrip(pastFutureOverride);
      setPaths([]);
    },
    []
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  // once closed, reinitialize state
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        reinitializeState(null, geotaggingEnabled ? 0 : 2);
      }, 400);
    }
  }, [open, reinitializeState]);

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          return {
            ...prevProgress,
            value: prevProgress.value >= 100 ? 0 : prevProgress.value + 5,
          };
        });
      }, 1500);
      return () => {
        clearInterval(timer);
      };
    }
  }, [loading]);

  // edge case to prevent files sticking around when
  // user switches from past to future
  // should only be called once user navigates back to first step
  useEffect(() => {
    if (pastOrFutureTrip && activeStep === 0) {
      reinitializeState(pastOrFutureTrip, activeStep);
    }
  }, [pastOrFutureTrip, activeStep, reinitializeState]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          handleClose();
          setActiveStep(0);
        }
      }}
      fullScreen={fullScreen}
      fullWidth={true}
      PaperProps={{
        component: "form",
      }}
    >
      <NewTripDialogTitle
        handleClose={handleClose}
        isFirstTrip={isFirstTrip}
        loading={loading}
      />
      <DialogContent sx={{ position: "relative" }}>
        {tripAlerts ? (
          <TripAlerts tripAlerts={tripAlerts} setTripAlerts={setTripAlerts} />
        ) : (
          ""
        )}
        <Stepper activeStep={activeStep} orientation="vertical">
          {geotaggingEnabled ? (
            <Step completed={pastOrFutureTrip ? true : false}>
              <NewTripDialogStep1Contents
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                pastOrFutureTrip={pastOrFutureTrip}
                setPastOrFutureTrip={setPastOrFutureTrip}
                loading={loading}
              />
            </Step>
          ) : (
            ""
          )}

          {geotaggingEnabled && pastOrFutureTrip === "past" ? (
            <Step completed={uploadedFiles?.length > 0 ? true : false}>
              <NewTripDialogStep2Contents
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                loading={loading}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                setTripAlerts={setTripAlerts}
                setSoloTraveler={setSoloTraveler}
                soloTraveler={soloTraveler}
                setPaths={setPaths}
                paths={paths}
                location={location}
                setLocation={setLocation}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                disabled={!pastOrFutureTrip}
                setLat={setLat}
                setLng={setLng}
              />
            </Step>
          ) : (
            ""
          )}

          <Step
            completed={title && location && startDate && endDate ? true : false}
            onClick={() => {
              pastOrFutureTrip && setActiveStep(2);
            }}
            disabled={loading}
          >
            <NewTripDialogStep3Contents
              geotaggingEnabled={geotaggingEnabled}
              title={title}
              setTitle={setTitle}
              location={location}
              setLocation={setLocation}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setLat={setLat}
              setLng={setLng}
              callee="dialog"
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              loading={loading}
              disabled={!pastOrFutureTrip}
              isPublic={isPublic}
              setIsPublic={setIsPublic}
            />
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        {!geotaggingEnabled ? (
          <SaveTripButton
            title={title}
            startDate={startDate}
            endDate={endDate}
            userId={userId}
            tripId={trip_id}
            location={location}
            lat={lat}
            lng={lng}
            description={description}
            setTrips={setTrips}
            fullWidth={true}
            loading={loading}
            setLoading={setLoading}
            callee="newtripdialog"
            setTripAlerts={setTripAlerts}
          />
        ) : (
          ""
        )}

        {activeStep === 0 ? (
          <Tooltip title={pastOrFutureTrip ? "" : RequiredFieldsTooltip}>
            <span>
              <Button
                onClick={() => {
                  setTripAlerts && setTripAlerts(null);
                  setActiveStep(pastOrFutureTrip === "past" ? 1 : 2);
                }}
                disabled={!pastOrFutureTrip}
                variant="contained"
              >
                Next
              </Button>
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        {geotaggingEnabled && activeStep === 1 ? (
          <>
            <Button
              onClick={() => {
                setTripAlerts && setTripAlerts(null);
                setActiveStep(0);
              }}
              disabled={loading}
            >
              Back
            </Button>

            <Button
              onClick={() => {
                setTripAlerts && setTripAlerts(null);
                setActiveStep(2);
              }}
              variant="contained"
            >
              {uploadedFiles.length ? "Next" : "Skip"}
            </Button>
          </>
        ) : (
          ""
        )}
        {geotaggingEnabled && activeStep === 2 ? (
          <>
            <Button
              onClick={() => {
                setTripAlerts && setTripAlerts(null);
                setActiveStep(pastOrFutureTrip === "past" ? 1 : 0);
              }}
              disabled={loading}
            >
              Back
            </Button>

            <SaveTripButton
              title={title}
              startDate={startDate}
              endDate={endDate}
              userId={userId}
              tripId={trip_id}
              location={location}
              lat={lat}
              lng={lng}
              description={description}
              setTrips={setTrips}
              fullWidth={true}
              loading={loading}
              setLoading={setLoading}
              uploadedFiles={uploadedFiles}
              soloTraveler={soloTraveler}
              variant="contained"
              callee="newtripdialog"
              setTripAlerts={setTripAlerts}
              setProgress={setProgress}
              setActiveStep={setActiveStep}
              isPublic={isPublic}
            />
          </>
        ) : (
          ""
        )}
      </DialogActions>

      {loading ? (
        <Loader
          styleOverrides={{
            height: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 100,
            background: "#FFFFFF90",
          }}
          progress={progress}
        />
      ) : (
        ""
      )}
    </Dialog>
  );
};
