import { useContext, useState, useEffect } from "react";
import { AppContext } from "../contexts";
import {
  Alert,
  Button,
  Grid,
  TextField,
  Typography,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  fetchCollaboratorsForTrip,
  addCollaborator,
  validateEmail,
  deleteCollaborator,
} from "../utils/collaborator_utils";
import { useParams, useNavigate } from "react-router-dom";
import { getFollowing } from "../utils/follow_utils";
import { CollaboratorAutocomplete, CollaboratorChip, WithCollapse } from ".";
import { TripAddCollaboratorsExplanation } from "../constants";

export const TripFieldsCollaborators = () => {
  const { collaborators, setCollaborators, trips, setTrips } =
    useContext(AppContext);
  const { trip_id } = useParams();
  const currentUserId = localStorage.getItem("user_id");
  let navigate = useNavigate();

  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const [addCollaboratorEmail, setAddCollaboratorEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [trip, setTrip] = useState(undefined);
  const [collaboratorAlert, setCollaboratorAlert] = useState(false);
  const [tripOwner, setTripOwner] = useState(undefined);
  const [currentUserFollowing, setCurrentUserFollowing] = useState({
    executed: false,
    response: undefined,
  });

  const handleAddCollaborator = async () => {
    if (isValidEmail) {
      setCollaboratorAlert(false);
      const collaboratorExists = collaborators.find(
        (collaborator) =>
          collaborator && collaborator.email === addCollaboratorEmail
      );
      if (collaboratorExists) {
        setCollaboratorAlert({
          severity: "error",
          message: "Collaborator already exists!",
        });
      } else if (typeof collaboratorExists === "undefined") {
        addCollaborator({
          tripId: trip_id,
          collaboratorEmail: addCollaboratorEmail,
          tripTitle: trip.title,
        }).then((collaborator) => {
          fetchCollaboratorsForTrip({ tripId: trip_id }).then(
            (collaborators) => {
              if (collaborators && !collaborators.error) {
                setCollaborators(collaborators);
              }
            }
          );
          // remove addCollaboratorEmail immediately
          if (addCollaboratorEmail) setAddCollaboratorEmail("");
          // show success alert for 5 seconds then remove it
          setCollaboratorAlert({
            severity: "success",
            message: `Email sent to ${collaborator.email}.`,
          });

          setTimeout(() => {
            setCollaboratorAlert(false);
          }, [5000]);
        });
      }
    }
  };

  const handleDeleteCollaborator = (index) => {
    deleteCollaborator({ id: collaborators[index].id }).then((result) => {
      fetchCollaboratorsForTrip({ tripId: trip_id }).then((collaborators) => {
        if (collaborators && !collaborators.error) {
          setCollaborators(collaborators);

          const currentUserIsOwnerOrCollaborator = [
            tripOwner,
            ...collaborators,
          ].find(
            (collaborator) =>
              collaborator &&
              collaborator.email === localStorage.getItem("email")
          );
          // filter trip user is no longer collaborator for from user's trips
          // to avoid race condition before redirect to home screen
          if (typeof currentUserIsOwnerOrCollaborator === "undefined") {
            const updatedTrips = trips.filter((trip) => trip.id !== trip_id);
            setTrips(updatedTrips);
            navigate("/home");
          }
        }
      });
    });
  };

  useEffect(() => {
    setIsValidEmail(validateEmail(addCollaboratorEmail));
  }, [addCollaboratorEmail]);

  useEffect(() => {
    if (trip_id) {
      fetchCollaboratorsForTrip({ tripId: trip_id }).then((collaborators) => {
        if (collaborators && !collaborators.error) {
          setCollaborators(collaborators);
          setTripOwner(collaborators && collaborators.find((c) => c.tripOwner));
        }
      });
    }
  }, [trip_id, setCollaborators]);

  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      setTrip(trips.find((trips) => trips.id === trip_id));
    }
  }, [trips, trip_id]);

  useEffect(() => {
    if (currentUserId && collaborators) {
      getFollowing({ id: currentUserId }).then((response) => {
        if (response) {
          // filter out accounts user is following that are already collaborators for this trip
          // https://stackoverflow.com/questions/31005396/filter-array-of-objects-with-another-array-of-objects
          // 41 upvoted answer :D
          const filteredFollowing =
            response &&
            response.filter((f) =>
              collaborators.every((c) => f.id !== c.userId)
            );

          setCurrentUserFollowing({
            executed: true,
            response: filteredFollowing || [],
          });
        }
      });
    }
  }, [currentUserId, collaborators]);

  return (
    <Stack direction="column" spacing={1}>
      {collaboratorAlert ? (
        <Alert
          severity={collaboratorAlert.severity}
          onClose={() => setCollaboratorAlert(false)}
        >
          {collaboratorAlert.message}
        </Alert>
      ) : (
        ""
      )}
      <Typography align="left" variant="body2" sx={{ color: "text.secondary" }}>
        {TripAddCollaboratorsExplanation}
      </Typography>

      {currentUserFollowing.executed ? (
        <Stack
          direction={screenIsSmall ? "column" : "row"}
          spacing={1}
          alignItems="center"
          justifyContent={screenIsSmall ? "flex-start" : "space-between"}
        >
          <CollaboratorAutocomplete
            currentUserFollowing={currentUserFollowing}
            addCollaboratorEmail={addCollaboratorEmail}
            setAddCollaboratorEmail={setAddCollaboratorEmail}
            label="Add email address"
            styleOverrides={{ width: "100%" }}
          />
          <Button
            onClick={handleAddCollaborator}
            fullWidth={screenIsSmall ? true : false}
            variant="contained"
            disabled={isValidEmail ? false : true}
            key={"add-collaborator-button"}
          >
            Add
          </Button>
        </Stack>
      ) : (
        <Skeleton width="100%">
          <TextField />
        </Skeleton>
      )}

      {collaborators && tripOwner ? (
        // should be grid as opposed to stack for desired overflow
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            cursor: "pointer",
          }}
        >
          <WithCollapse
            collapse={collaborators.length > 5}
            collapsedSize={"75px"}
            children={collaborators.map((collaborator, index) => {
              if (collaborator) {
                let isDeletable = false;

                // tripOwner collaborator should never be deletable by anyone
                if (!collaborator.tripOwner) {
                  // if currentUser is owner, they can delete anyone but themselves
                  if (currentUserId === tripOwner.id) {
                    isDeletable = true;
                  } // currentUser matches collaborator, they can delete themselves
                  else if (collaborator.userId === currentUserId) {
                    isDeletable = true;
                  }
                }

                let tooltipTitle;
                if (collaborator.tripOwner) {
                  tooltipTitle = "Trip owner";
                } else if (collaborator.userId) {
                  tooltipTitle = "Editor";
                } else {
                  tooltipTitle = "Unverified account";
                }

                return (
                  <CollaboratorChip
                    collaborator={collaborator}
                    index={index}
                    handleDeleteCollaborator={
                      isDeletable ? handleDeleteCollaborator : null
                    }
                    key={`collaborator-chip-${index}`}
                    tooltipTitle={tooltipTitle}
                  />
                );
              }
              return [];
            })}
          />
        </Grid>
      ) : null}
    </Stack>
  );
};
