// TO DO
// could be condensed to one function
// same for controller function???
export const getFollowerCount = async ({ id }) => {
  try {
    const response = await fetch(`/api/follower/count/${id}`);
    const followerCount = await response.json();
    return followerCount;
  } catch (e) {
    console.log({ e });
  }
};

export const getFollowingCount = async ({ id }) => {
  try {
    const response = await fetch(`/api/following/count/${id}`);
    const followingCount = await response.json();
    return followingCount;
  } catch (e) {
    console.log({ e });
  }
};

export const getFollowers = async ({ id }) => {
  try {
    const response = await fetch(`/api/followers/${id}`);
    const followers = await response.json();
    return followers;
  } catch (e) {
    console.log({ e });
  }
};

export const getFollowing = async ({ id }) => {
  try {
    const response = await fetch(`/api/following/${id}`);
    const following = await response.json();
    return following;
  } catch (e) {
    console.log({ e });
  }
};

export const followUser = async ({ profileId, currentUserId }) => {
  try {
    const response = await fetch(`/api/follow`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profileId,
        currentUserId,
      }),
    });
    const follower = await response.json();
    return follower;
  } catch (e) {
    console.log({ e });
  }
};

export const unfollowUser = async ({ profileId, currentUserId }) => {
  try {
    const response = await fetch(`/api/follow/delete`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profileId,
        currentUserId,
      }),
    });
    return response;
  } catch (e) {
    console.log({ e });
  }
};

export const removeFollower = async ({ profileId, currentUserId }) => {
  try {
    const response = await fetch(`/api/follower/delete`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profileId,
        currentUserId,
      }),
    });
    return response;
  } catch (e) {
    console.log({ e });
  }
};

export const getSuggestedProfiles = async ({ profiles }) => {
  try {
    const response = await fetch(`/api/following/suggested`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profiles,
      }),
    });
    const suggestedProfiles = await response.json();
    // issue with suggested profiles containing null, filter out for now
    const filteredSuggestedProfiles = suggestedProfiles.filter((e) => e);
    return filteredSuggestedProfiles;
  } catch (e) {
    console.log({ e });
  }
};
