import { useContext, useState } from "react";
import { Grid, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { AppContext } from "../contexts";
import { utcDate } from "../utils/date_utils";
import { EmojiPickerButton } from "./Buttons";
import { EmojiPickerMenu } from "./";
import {
  handleEmojiPickerClose,
  handleEmojiIconButtonOnClick,
  handleEmojiSelection,
} from "../utils/emoji_utils";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";

export const EventFields1 = ({
  loading,
  title,
  setTitle,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const { endDate, startDate } = useContext(AppContext);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <TextField
            label="Title"
            fullWidth={true}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            required
            value={title}
            disabled={loading ? true : false}
            id="text-field-title"
            autoFocus={true}
          />
          {screenIsSmall ? (
            ""
          ) : (
            <EmojiPickerButton
              onClick={({ event }) =>
                handleEmojiIconButtonOnClick({
                  event,
                  setAnchorEl,
                  emojiPickerOpen,
                  setEmojiPickerOpen,
                })
              }
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <DateTimeRangePicker
          localeText={{ start: "Start time", end: "End time" }}
          onChange={(newValue) => {
            newValue[0] && setStartTime(utcDate(newValue[0].$d));
            newValue[1] && setEndTime(utcDate(newValue[1].$d));
          }}
          value={[startTime, endTime]}
          minDate={startDate}
          maxDate={endDate}
          disabled={loading ? true : false}
        />
      </Grid>
      {screenIsSmall ? (
        ""
      ) : (
        <EmojiPickerMenu
          emojiPickerOpen={emojiPickerOpen}
          anchorEl={anchorEl}
          onClose={() =>
            handleEmojiPickerClose({ setAnchorEl, setEmojiPickerOpen })
          }
          onSelection={({ emoji }) =>
            handleEmojiSelection({ emoji, str: title, setStr: setTitle })
          }
        />
      )}
    </Grid>
  );
};
