export const logPageView = async ({
  userId = null,
  sessionId = null,
  url,
  referrerUrl = null,
}) => {
  try {
    const response = await fetch(`/api/view/log`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        sessionId,
        url,
        referrerUrl,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};
