import { Button, Grid, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const NewTripButton = ({ setNewTripDialogOpen, isFirstTrip }) => {
  return isFirstTrip ? (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ height: "80vh", width: "100%" }}
    >
      <Button
        onClick={() => setNewTripDialogOpen(true)}
        variant="contained"
        size="large"
        endIcon={<AddCircleIcon />}
      >
        Create First Trip
      </Button>
    </Stack>
  ) : (
    <Grid item xs={12} mt={2} sx={{ textAlign: "left" }}>
      <Button
        onClick={() => setNewTripDialogOpen(true)}
        variant="contained"
        size="large"
        endIcon={<AddCircleIcon />}
      >
        Create Trip
      </Button>
    </Grid>
  );
};
