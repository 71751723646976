import { createRouter } from "./router";
import { RouterProvider } from "react-router-dom";
import { useMemo } from "react";
export const AppWrapper = ({ initialEntries }) => {
  const router = useMemo(
    () => createRouter({ initialEntries }),
    [initialEntries],
  );
  return <RouterProvider router={router} />;
};
