export const handleEmojiIconButtonOnClick = ({
  event,
  setAnchorEl,
  emojiPickerOpen,
  setEmojiPickerOpen,
}) => {
  setAnchorEl(event.currentTarget);
  setEmojiPickerOpen(!emojiPickerOpen);
};

export const handleEmojiPickerClose = ({ setAnchorEl, setEmojiPickerOpen }) => {
  setAnchorEl(null);
  setEmojiPickerOpen(false);
};

export const handleEmojiSelection = ({ emoji, str, setStr }) => {
  const newTitle = str + emoji;
  setStr(newTitle);
};
