import { Grid, Stack } from "@mui/material";
import { ToggleViewButtons } from "./Buttons";

export const ToggleViewBar = ({
  items,
  view,
  setView,
  setPage,
  setRowsPerPage,
  title,
  callee,
  titlePosition = "before",
}) => {
  const currentUserId = localStorage.getItem("user_id");

  const handleView = (event, newView) => {
    event.stopPropagation();
    if (newView !== null) {
      setView(newView);
      setPage && setPage(0);
      setRowsPerPage && setRowsPerPage(6);
      if (currentUserId && callee) {
        localStorage.setItem(`${callee}_view`, newView);
      }
    }
  };

  return (
    <Grid item xs={12}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="top"
        spacing={1}
      >
        {titlePosition === "before" ? title : ""}
        <ToggleViewButtons view={view} handleView={handleView} items={items} />
        {titlePosition === "after" ? title : ""}
      </Stack>
    </Grid>
  );
};
