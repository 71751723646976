import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ExpandMoreButton = styled((props) => {
  const { expand, title, ...other } = props;
  return (
    <Tooltip title={title}>
      <IconButton {...other} />
    </Tooltip>
  );
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  margin: "0 auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
