import { Chip, Tooltip, Avatar, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { convertToBase64 } from "../utils/storage_utils";

// only trip owners should be able to remove other collaborators
// all collaborators can remove themselves
export const CollaboratorChip = ({
  collaborator,
  index,
  handleDeleteCollaborator,
  tooltipTitle,
  size,
  variant,
}) => {
  let navigate = useNavigate();
  const confirm = useConfirm();
  const theme = useTheme();

  const userId = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : null;

  const label =
    collaborator.firstName && collaborator.lastName
      ? `${collaborator.firstName} ${collaborator.lastName}`
      : collaborator && collaborator.email;

  const deleteConfirmProps = handleDeleteCollaborator
    ? {
        title: "Confirm",
        description:
          collaborator.userId === userId
            ? "Remove yourself as a collaborator? You will lose access to this trip."
            : `Remove ${label} as a collaborator? They will lose access to this trip.`,
        confirmationButtonProps: { variant: "contained" },
      }
    : null;

  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        label={label}
        size={size ? size : "medium"} // default
        variant={variant ? variant : "filled"} // default
        onDelete={
          handleDeleteCollaborator
            ? (e) => {
                e.stopPropagation();
                confirm(deleteConfirmProps)
                  .then(() => {
                    handleDeleteCollaborator(index);
                  })
                  .catch((e) => {});
              }
            : null
        }
        onClick={(event) => {
          if (collaborator.userId) {
            event.stopPropagation();
            navigate(`/profile/${collaborator.userId}`);
          }
        }}
        avatar={
          avatarOrIcon({ collaborator }).avatar ? (
            <Avatar src={convertToBase64(collaborator.file)}>
              {collaborator.firstName && collaborator.firstName[0]}
            </Avatar>
          ) : null
        }
        icon={
          avatarOrIcon({ collaborator }).avatar
            ? null
            : avatarOrIcon({ collaborator }).icon &&
              avatarOrIcon({ collaborator }).icon
        }
        clickable={collaborator.userId ? true : false}
        sx={{ margin: theme.spacing(0.5) }}
      />
    </Tooltip>
  );
};

const avatarOrIcon = ({ collaborator }) => {
  if (collaborator.userId && collaborator.file.message !== "File not found") {
    return {
      avatar: true,
    };
  } else if (collaborator.userId && collaborator.firstName) {
    return {
      avatar: true,
    };
  } else if (collaborator.userId && !collaborator.firstName.length) {
    return {
      avatar: false,
      icon: <VerifiedUserIcon />,
    };
  } else if (!collaborator.userId) {
    return {
      avatar: false,
    };
  }
  // catch all
  return {
    avatar: false,
  };
};
