import { CoverPhoto, UploadCoverPhoto } from "./";
import { Skeleton } from "@mui/material";
import { FileNotFoundMessage } from "../constants";
export const TripFileDisplay = ({
  tripFile,
  selectFileHandler,
  deleteHandler,
  loading,
  setLoading,
  alert,
  setAlert,
  currentUserIsEditor,
  styleOverrides,
}) => {
  if (tripFile === "loading") {
    return <Skeleton variant="rectangular" sx={{ ...styleOverrides }} />;
  } else if (tripFile === FileNotFoundMessage) {
    // needs to be separate or returns broken image
    if (currentUserIsEditor) {
      return (
        <UploadCoverPhoto
          selectFileHandler={selectFileHandler}
          loading={loading}
          setLoading={setLoading}
          alert={alert}
          setAlert={setAlert}
          styleOverrides={styleOverrides}
        />
      );
    }
    return;
  } else if (tripFile) {
    return (
      <CoverPhoto
        file={tripFile}
        currentUserIsEditor={currentUserIsEditor}
        deleteHandler={deleteHandler}
      />
    );
  }
};
