import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Focus from "@tiptap/extension-focus";
import Placeholder from "@tiptap/extension-placeholder";

export const extensions = [
  StarterKit,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Highlight,
  Focus.configure({
    className: "has-focus",
    mode: "all",
  }),
  Placeholder.configure({
    placeholder: "Add Description",
    showOnlyWhenEditable: false,
  }),
];
