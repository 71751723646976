import { Alert, Grid } from "@mui/material";

export const TripAlerts = ({ tripAlerts, setTripAlerts }) => {
  return tripAlerts?.map(({ severity, message }, index) => {
    return (
      <Grid key={`encodeURIComponent(message)${index}`} item xs={12} mb={2}>
        <Alert
          severity={severity}
          onClose={(index) => {
            let tripAlertsCopy = [...tripAlerts];
            tripAlertsCopy.splice(index, 1);
            setTripAlerts(tripAlertsCopy);
          }}
        >
          {message}
        </Alert>
      </Grid>
    );
  });
};
