import React from "react";
import {
  AppBar,
  Chip,
  Grid,
  IconButton,
  Link,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo_appbar.png";
import { headerAppBarLogoStyles } from "../styles";

export const HeaderAppBarPublic = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  let redirectUrl = "";
  if (location.pathname.includes("/trip/share")) {
    redirectUrl = `/?redirect_url=${location.pathname}`;
  } else if (location.pathname.includes("/profile")) {
    redirectUrl = `/?redirect_url=${location.pathname}`;
  } else if (location.pathname.includes("/explore")) {
    redirectUrl = `/?redirect_url=${location.pathname}${location.search}`; // also want search term if it exists
  }
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                cursor: "pointer",
              }}
              xs={2}
            >
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  navigate("/explore");
                }}
              >
                <SearchIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              xs={8}
            >
              <Link
                sx={{
                  margin: "0 auto",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // push to local storage due to refresh associated with social login provider
                  localStorage.setItem(
                    "redirect_url",
                    `${location.pathname}${location.search}`
                  );
                  navigate(redirectUrl);
                }}
              >
                <img
                  alt="Trippy text logo"
                  src={logo}
                  style={{ height: "40px", ...headerAppBarLogoStyles }}
                />
              </Link>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                textAlign: "right",
              }}
              xs={2}
            >
              <Chip
                sx={{ marginLeft: "auto", color: "white" }}
                icon={<PersonIcon color="white" />}
                label={screenIsSmall ? "" : "Sign up!"}
                onClick={() => {
                  // same as line 63
                  localStorage.setItem("redirect_url", location.pathname);
                  navigate(redirectUrl);
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
