import { snakeToCamel } from "../utils/text_utils";
import { convertToBase64 } from "./storage_utils";

export const fetchTripsForUser = async ({ userId }) => {
  try {
    const response = await fetch(`/api/trips/user/${userId}`);
    let { trips } = await response.json();
    return trips;
  } catch (e) {
    // console.log({ e });
  }
};

export const deleteTrip = async ({ tripId }) => {
  try {
    const response = await fetch(`/api/trips/delete`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: tripId,
      }),
    });
    return response;
  } catch (e) {
    console.log({ e });
  }
};

export const saveTrip = async ({
  title,
  startDate,
  endDate,
  userId,
  tripId,
  location,
  lat,
  lng,
  originalTripId,
  description,
}) => {
  try {
    const response = await fetch(`/api/trips`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        startDate,
        endDate,
        userId,
        tripId,
        location,
        lat,
        lng,
        originalTripId,
        description,
        // googleMapId: mapUrl,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};

export const incrementTripViewCounter = async ({ id }) => {
  try {
    const response = await fetch(`/api/trips/increment`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};

export const toggleTripPublic = async ({ id, isPublic }) => {
  try {
    const response = await fetch(`/api/trips/togglepublic`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        isPublic,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};

// TO DO investigate where this gets called and if it's used
export const fetchTripById = async ({ tripId }) => {
  try {
    const response = await fetch(`/api/trips/${tripId}`);
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};

export const copyTrip = ({ originalTrip, updatedTripInfo }) => {
  // convert originalTrip keys from snake to camel
  const originalTripCopy = {};
  for (const [key, value] of Object.entries(originalTrip)) {
    originalTripCopy[snakeToCamel(key)] = value;
  }

  // merge originalTrip with updatedTripInfo
  // updatedTripInfo should override
  let newTrip = { ...originalTripCopy, ...updatedTripInfo };
  // update / delete other necessary properties
  newTrip.originalTripId = originalTripCopy.originalTripId
    ? originalTripCopy.originalTripId
    : originalTripCopy.id;
  delete newTrip.id;
  // delete newTrip.checked;
  newTrip.publicViews = 0;
  // associate newTrip with current user
  newTrip.userId = localStorage.getItem("user_id");

  return newTrip;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const retrieveCoverPhotos = async ({ tripIds }) => {
  try {
    const response = await fetch(`/api/trips/coverphotos`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tripIds,
      }),
    });
    let result = await response.json();
    const coverPhotosObj = {};
    result.forEach((t) => {
      coverPhotosObj[t.id] = fileHelper(t.file);
    });
    return coverPhotosObj;
  } catch (e) {
    console.log({ e });
  }
};

const fileHelper = (file) => {
  if (file.message !== "File not found" || !file.error) {
    return { ...file, base64: convertToBase64(file) };
  }
};

export const saveTripDescription = async ({ tripId, description }) => {
  try {
    const response = await fetch(`/api/trips/description`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tripId,
        description,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};

export const fetchTripsByLocation = async ({ location, lat, lng, n }) => {
  try {
    const response = await fetch(`/api/trips/location`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        location,
        lat,
        lng,
        n,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};
