import { IconButton, Tooltip } from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";

export const CopiedOrOriginal = ({ trip }) => {
  const navigate = useNavigate();

  return trip.original_trip_id ? (
    <Tooltip title="This trip was copied from another trip. Click to view the original trip.">
      <IconButton
        onClick={() => {
          navigate(`/trip/share/${trip.original_trip_id}`);
        }}
      >
        <PublishedWithChangesIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title="This trip was originally created by this user!">
      <span>
        <IconButton disabled={true}>
          <VerifiedIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
