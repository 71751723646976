import { Button, Tooltip } from "@mui/material";
import { fetchEventsForTrip, saveEvent } from "../../utils/event_utils";
import { RequiredFieldsTooltip } from "../../constants";

export const SaveEventButton = ({
  title,
  startTime,
  endTime,
  description,
  location,
  lat,
  lng,
  type,
  favorite,
  loading,
  setLoading,
  setAlert,
  editable,
  eventClicked,
  tripId,
  userId,
  setEvents,
  handleClose,
  variant,
  label,
}) => {
  return (
    <Tooltip title={title && startTime && endTime ? "" : RequiredFieldsTooltip}>
      <span>
        <Button
          aria-label={label}
          variant={variant}
          disabled={!title || loading || !editable ? true : false}
          onClick={() => {
            // check just in case
            if (endTime > startTime) {
              setLoading(true);
              setAlert(false);
              // existing event should use original creator id
              // new one can use currentUserId
              const creatorUserId =
                eventClicked && eventClicked.creator
                  ? eventClicked.creator.id
                  : userId;
              saveEvent({
                title,
                startTime,
                endTime,
                tripId,
                userId: creatorUserId,
                description,
                id: eventClicked ? eventClicked.id : null,
                location,
                lat,
                lng,
                type,
                favorite,
              }).then((event) => {
                fetchEventsForTrip({
                  tripId,
                  callee: "edit",
                }).then((events) => {
                  setEvents(events);
                });
                handleClose();
              });
            } else {
              setAlert({
                severity: "error",
                message: "Start time should not be after end time!",
              });
            }
          }}
        >
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};
