import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  CollaboratorChip,
  CopiedOrOriginal,
  LocationChip,
  TripFileDisplay,
  WithCollapse,
} from "./";
import {
  CopyURLConfirmMessage,
  CopyShareTripTooltip,
  ExploreShareTripLocationTooltip,
} from "../constants";
import {
  CopyToClipboardButton,
  CopyTripButton,
  EditTripButton,
} from "./Buttons";
import { TipTapWrappers } from "./Rte/TipTapWrappers";

export const ShareTripSummary = ({
  tripFile,
  selectFileHandler,
  deleteHandler,
  tripOwner,
  loading,
  setLoading,
  setAlert,
  alert,
  hasSession,
  trip,
  currentUserIsEditor,
  setCopyTripDialogOpen,
  collaborators,
}) => {
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  const [description, setDescription] = useState(trip.description);

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TripFileDisplay
            tripFile={tripFile}
            selectFileHandler={selectFileHandler}
            deleteHandler={deleteHandler}
            tripOwner={tripOwner}
            loading={loading}
            setLoading={setLoading}
            setAlert={setAlert}
            alert={alert}
            currentUserIsEditor={currentUserIsEditor}
          />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              marginTop: `${theme.spacing(2)} !important`,
              textAlign: "left",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="h6">{trip.title}</Typography>
              <CopiedOrOriginal trip={trip} />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <LocationChip
                tooltipTitle={ExploreShareTripLocationTooltip}
                label={trip.location}
                url={`/explore?query=${encodeURIComponent(trip.location)}&lat=${
                  trip.lat
                }&lng=${trip.lng}`}
                clickable={trip.location && trip.lat && trip.lng ? true : false}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <TipTapWrappers
                content={description}
                setContent={setDescription}
                tripId={trip.id}
                loading={loading}
                setLoading={setLoading}
                callee="sharetrip"
                currentUserIsEditor={currentUserIsEditor}
              />
            </Stack>
            <Stack
              direction={screenIsSmall ? "column" : "row"}
              justifyContent={screenIsSmall ? "flex-start" : "space-between"}
              spacing={1}
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  {trip.public_views} lifetime views
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <CopyTripButton
                  disabled={!hasSession}
                  setCopyTripDialogOpen={setCopyTripDialogOpen}
                />
                {currentUserIsEditor ? <EditTripButton trip={trip} /> : ""}

                <CopyToClipboardButton
                  url={`${window.location.origin}/trip/share/${trip.id}`}
                  icon={<IosShareIcon />}
                  confirmMessage={CopyURLConfirmMessage}
                  tooltipTitle={CopyShareTripTooltip}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{ marginTop: `${theme.spacing(2)} !important` }}
            >
              <WithCollapse
                collapse={screenIsSmall && collaborators.length > 5}
                collapsedSize="75px"
                children={
                  collaborators &&
                  collaborators.map((collaborator, index) => {
                    return (
                      <CollaboratorChip
                        collaborator={collaborator}
                        handleDeleteCollaborator={null}
                        key={`collaborator-chip-${index}`}
                        tooltipTitle={
                          collaborator.tripOwner
                            ? "Trip owner"
                            : "Trip collaborator"
                        }
                      />
                    );
                  })
                }
              />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
