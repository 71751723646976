import { Grid, StepLabel, Typography } from "@mui/material";
import { UploadPhotosGenerateEventsDetails } from "../";
import { NewTripDialogStep2Explanation } from "../../constants";

export const NewTripDialogStep2Contents = ({
  activeStep,
  setActiveStep,
  loading,
  uploadedFiles,
  setUploadedFiles,
  setTripAlerts,
  setSoloTraveler,
  soloTraveler,
  paths,
  setPaths,
  location,
  setLocation,
  setStartDate,
  setEndDate,
  disabled,
  setLat,
  setLng,
}) => {
  let optionalLabel = "Optional";
  if (uploadedFiles.length) {
    optionalLabel = `Optional  - ${
      uploadedFiles.length > 1
        ? uploadedFiles.length + " photos"
        : uploadedFiles.length + " photo"
    } selected`;
  }
  return (
    <>
      <StepLabel
        onClick={() => {
          !disabled && setActiveStep(1);
        }}
        optional={<Typography variant="caption">{optionalLabel}</Typography>}
        disabled={loading}
      >
        {NewTripDialogStep2Explanation}
      </StepLabel>

      {activeStep === 1 ? (
        <Grid item xs={12} mt={2}>
          <UploadPhotosGenerateEventsDetails
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            soloTraveler={soloTraveler}
            setSoloTraveler={setSoloTraveler}
            loading={loading}
            setTripAlerts={setTripAlerts}
            paths={paths}
            setPaths={setPaths}
            location={location}
            setLocation={setLocation}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setLat={setLat}
            setLng={setLng}
          />
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
