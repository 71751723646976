import { Chip, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";

export const LocationChip = ({
  clickable,
  label,
  tooltipTitle = "",
  url,
  icon = <PlaceIcon />,
}) => {
  const navigate = useNavigate();

  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        icon={icon}
        label={label}
        variant="outlined"
        onClick={(event) => {
          if (clickable) {
            navigate(url);
            event.stopPropagation();
          }
        }}
        clickable={clickable}
      />
    </Tooltip>
  );
};
