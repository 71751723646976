import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuList,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { AccountDialog } from "../Modals";
import React, { useState, useEffect } from "react";
import { getFile, convertToBase64 } from "../../utils/storage_utils";
import { setLocalStorageItem } from "../../utils/user_utils";
import logo from "../../assets/images/logo_appbar.png";
import { headerAppBarLogoStyles } from "../styles";

export const HeaderAppBar = ({ handleSignOut }) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  const userId = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : null;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);
  const [avatarFileStr, setAvatarFileStr] = useState(undefined);
  const [avatarFileExtension, setAvatarFileExtension] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // file logic here before rendering modal
  useEffect(() => {
    if (localStorage.getItem("current_user_avatar_url")) {
      setLoading(false);
    } else if (userId) {
      getFile({ id: userId, bucket: "avatars" }).then((file) => {
        if (file) {
          if (file.message === "File not found") {
            setAvatarFileStr(file.message);
          } else if (!file.error) {
            setAvatarFileStr(convertToBase64(file));
            setAvatarFileExtension(file.extension);

            try {
              setLocalStorageItem({
                key: "current_user_avatar_url",
                value: convertToBase64(file),
              });
            } catch (e) {
              console.log({ e });
            }
          }
          setLoading(false);
        }
      });
    }
  }, [userId]);

  const firstName = localStorage.getItem("first_name");
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                cursor: "pointer",
              }}
              xs={2}
            >
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  // reload if on home previously to reset trips pagination
                  if (window.location.pathname === "/home") {
                    window.location.reload();
                  }
                  navigate("/home");
                }}
              >
                <HomeIcon />
              </IconButton>
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  navigate("/explore");
                }}
              >
                <SearchIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              xs={8}
            >
              <Link
                href={`${window.location.origin}`}
                sx={{
                  margin: "0 auto",
                  cursor: "pointer",
                }}
              >
                <img
                  alt="Trippy text logo"
                  src={logo}
                  style={{ height: "40px", ...headerAppBarLogoStyles }}
                />
              </Link>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                textAlign: "right",
                visibility: loading ? "hidden" : "visible",
              }}
              xs={2}
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleMenuClick}
            >
              <Avatar
                component="label"
                variant="contained"
                sx={{ marginLeft: "auto", color: "white" }}
                src={localStorage.getItem("current_user_avatar_url")}
              >
                {firstName && firstName[0]}
              </Avatar>

              {screenIsSmall ? (
                ""
              ) : (
                <Typography
                  sx={{ marginLeft: "10px", display: "inline" }}
                  noWrap
                  component="span"
                >
                  {`Hi, ${firstName}!`}
                </Typography>
              )}
            </Grid>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={() => {
                handleMenuClose();
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* only show home on profile pages and profile everywhere else*/}
              {window.location.href.includes("/profile/") ? (
                <MenuItem
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <ListItemIcon>
                    <HomeIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Home</ListItemText>
                </MenuItem>
              ) : (
                ""
              )}

              <MenuItem
                onClick={() => {
                  navigate(`/profile/${userId}`);
                }}
              >
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
              {/* only show sign out and account info from pages with context, i.e. home, edit trip */}
              {handleSignOut ? (
                <MenuList>
                  <Divider />

                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <InfoIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Info</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleSignOut();
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </MenuList>
              ) : (
                ""
              )}
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <AccountDialog
        open={modalOpen}
        handleClose={handleModalClose}
        avatarFileStr={avatarFileStr}
        setAvatarFileStr={setAvatarFileStr}
        setAvatarFileExtension={setAvatarFileExtension}
      />
    </>
  );
};
