import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";

export const ToggleViewButtons = ({
  view,
  handleView,
  items,
  exclusive = true,
}) => {
  return (
    <ToggleButtonGroup
      value={view}
      exclusive={exclusive}
      onChange={handleView}
      aria-label="toggle view"
    >
      {items.map(({ value, icon, tooltip, disabled = false }) => {
        return (
          <Tooltip title={tooltip} key={`toggle-view-button-${value}`}>
            <span>
              <ToggleButton
                value={value}
                aria-label={value}
                size="small"
                disabled={disabled}
              >
                {icon}
              </ToggleButton>
            </span>
          </Tooltip>
        );
      })}
    </ToggleButtonGroup>
  );
};
