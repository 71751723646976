import { Grid, Alert, useMediaQuery, useTheme } from "@mui/material";
import { UploadButton } from "./";
import { allowedTripFileInfo } from "../constants";

export const UploadCoverPhoto = ({
  selectFileHandler,
  loading,
  setLoading,
  alert,
  setAlert,
  styleOverrides,
}) => {
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  return (
    <Grid container>
      {alert ? (
        <Grid item xs={12}>
          <Alert
            severity={alert.severity}
            onClose={(e) => {
              e.stopPropagation();
              setAlert(false);
            }}
          >
            {alert.message}
          </Alert>
        </Grid>
      ) : (
        ""
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: theme.palette.grey["100"],
          width: "100%",
          height: screenIsSmall ? "125px" : "200px",
          ...styleOverrides, // last
        }}
      >
        <UploadButton
          selectFileHandler={selectFileHandler}
          allowedFileInfo={allowedTripFileInfo}
          setAlert={setAlert}
          loading={loading}
          setLoading={setLoading}
          label="Add Cover Photo"
        />
      </Grid>
    </Grid>
  );
};
