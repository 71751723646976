import { useMemo } from "react";
import { Grid, Stack } from "@mui/material";
import { getComparator, stableSort } from "../utils/trip_utils";
import { TripCard, TripsPagination } from "./";

export const GridView = ({
  callee,
  trips,
  setTrips,
  actions,
  height,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  tripsCoverPhotos,
  setTripCoverPhotos,
}) => {
  const sortedTrips = useMemo(() => {
    // localStorage should only be used for home table
    callee === "home" &&
      localStorage.setItem(
        "trips_table_sort",
        JSON.stringify({ order, orderBy })
      );
    return stableSort(trips, getComparator(order, orderBy));
  }, [order, orderBy, trips, callee]);

  return (
    sortedTrips && (
      <Grid container spacing={2}>
        {(rowsPerPage > 0
          ? sortedTrips.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : sortedTrips
        ).map((trip, index) => {
          return (
            <Grid item xs={12} md={4} sx={{ textAlign: "left" }} key={trip.id}>
              <TripCard
                trip={trip}
                trips={sortedTrips}
                actions={actions}
                setTrips={setTrips}
                callee={callee}
                tripsCoverPhotos={tripsCoverPhotos}
                setTripCoverPhotos={setTripCoverPhotos}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
          );
        })}
        {trips.length > 6 ? ( //should always be first entry in rowsPerPage option
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ marginTop: 1, width: "100%" }}
          >
            <TripsPagination
              component="div"
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              rows={trips}
            />
          </Stack>
        ) : (
          ""
        )}
      </Grid>
    )
  );
};
