import { useState } from "react";
import {
  Button,
  Tooltip,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoIcon from "@mui/icons-material/Info";

export const FollowUnfollowButton = ({
  isFollower,
  hasSession,
  topLevelUnfollowHandler,
  topLevelFollowHandler,
  profile,
  loading,
  setLoading,
  ownProfile,
  setDialogItems,
  setDialogTitle,
  suggestedProfiles,
  handleClickOpen,
}) => {
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirm = useConfirm();
  if (ownProfile) {
    return suggestedProfiles.executed && suggestedProfiles.response.length ? (
      <Button
        id="suggested-profiles-button"
        onClick={() => {
          setDialogTitle("Suggested Profiles");
          setDialogItems(suggestedProfiles.response);
          handleClickOpen();
        }}
        disabled={
          !hasSession || (loading && Object.hasOwn(loading, profile.id))
            ? true
            : false
        }
        endIcon={
          <Tooltip title="Collaborators from your trips you do not follow">
            <InfoIcon />
          </Tooltip>
        }
      >
        {/* shorten button text on small screen to prevent overlap */}
        {screenIsSmall ? "Suggested" : "Suggested Profiles"}
      </Button>
    ) : (
      ""
    );
  } else if (isFollower) {
    return (
      <div>
        <Button
          id="following-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disabled={
            !hasSession || (loading && Object.hasOwn(loading, profile.id))
              ? true
              : false
          }
          endIcon={<KeyboardArrowDownIcon />}
        >
          Following
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              const nameOrEmail =
                profile.first_name && profile.last_name
                  ? `${profile.first_name} ${profile.last_name}`
                  : `${profile.email}`;
              confirm({
                title: "Confirm",
                description: `Unfollow ${nameOrEmail}?`,
                confirmationButtonProps: { variant: "contained" },
              })
                .then(() => {
                  setLoading({ ...loading, [profile.id]: true });
                  topLevelUnfollowHandler({ profileId: profile.id });
                  handleClose();
                })
                .catch((e) => {
                  console.log({ e });
                });
            }}
          >
            Unfollow
          </MenuItem>
        </Menu>
      </div>
    );
  } else if (typeof isFollower !== "undefined") {
    return (
      <Tooltip
        title={
          hasSession ? "" : "You must be logged in to perform this action."
        }
        placement="bottom-end"
      >
        <div>
          <Button
            variant="contained"
            onClick={() => {
              // setLoading({ status: true, id: profile.id });
              setLoading({ ...loading, [profile.id]: true });
              topLevelFollowHandler({ profileId: profile.id });
            }}
            disabled={
              !hasSession || (loading && Object.hasOwn(loading, profile.id))
                ? true
                : false
            }
            endIcon={<AddIcon />}
          >
            Follow
          </Button>
        </div>
      </Tooltip>
    );
  }
};
