import { Grid, IconButton, Stack, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";

export const CoverPhoto = ({ file, currentUserIsEditor, deleteHandler }) => {
  const confirm = useConfirm();
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sx={{
        position: "relative",
        "& .hiddenChild": {
          visibility: "hidden",
        },
        "&:hover .hiddenChild": {
          opacity: 1,
          visibility: "visible",
        },
      }}
    >
      {currentUserIsEditor && deleteHandler ? (
        <Stack
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          spacing={1}
          direction="row"
        >
          <IconButton
            className="hiddenChild"
            sx={{
              backgroundColor: `${theme.palette.grey["100"]}`,
              opacity: 0,
              "&:hover": {
                backgroundColor: theme.palette.grey["100"],
                opacity: 1,
                visibility: "visible",
              },
            }}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              confirm({
                title: "Confirm",
                description: "Deleting the cover photo is permanent!",
                confirmationButtonProps: { variant: "contained" },
              })
                .then(() => {
                  deleteHandler();
                })
                .catch((e) => {
                  // console.log({ e });
                });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ) : (
        ""
      )}

      <img
        alt="cover"
        src={file && file.base64}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "auto",
        }}
      />
    </Grid>
  );
};
