import ReactGA from "react-ga4";
import React, { useEffect, useState, useCallback } from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsconfig from "./aws-exports";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";
import { LicenseInfo } from "@mui/x-license";
import { fetchTripsForUser } from "./utils/trip_utils";
import { logPageView } from "./utils/view_utils";
import { setLocalStorageItem } from "./utils/user_utils";
import {
  fetchOrCreateUser,
  handleFetchUserAttributes,
} from "./utils/user_utils";
import { AppContext } from "./contexts";
import { HeaderAppBar, FooterAppBar } from "./Components/Layout";
import { Theme } from "./Theme";
import "./App.css";
import "./styles.css";
// Should this still be here?
// https://github.com/aws-amplify/amplify-cli/issues/2792#issuecomment-671944193
// & https://github.com/aws-amplify/amplify-cli/issues/1880
// & https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-add-custom-domain.html
awsconfig.oauth.domain = "auth.trippyapp.xyz";
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`; // home/
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_LICENSE_KEY);

export const App = ({ signOut, user }) => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

  const [places, setPlaces] = useState();
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [events, setEvents] = useState([]);
  const [tripTitle, setTripTitle] = useState(undefined);
  const [tripLocation, setTripLocation] = useState(undefined);
  const [trips, setTrips] = useState(undefined);
  const [collaborators, setCollaborators] = useState([]);
  const [eventFileStr, setEventFileStr] = useState(undefined);
  const [eventFileExtension, setEventFileExtension] = useState(undefined);
  const [showSnackbar, setShowSnackbar] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const handleSignOut = useCallback(() => {
    window.localStorage.clear();
    navigate("/");
    signOut();
  }, [navigate, signOut]);

  useEffect(() => {
    initializeUser();
  }, []);

  function initializeUser() {
    if (
      !(
        localStorage.getItem("email") ||
        localStorage.getItem("first_name") ||
        localStorage.getItem("last_name") ||
        localStorage.getItem("user_id")
      )
    ) {
      handleFetchUserAttributes().then(({ userAttributes }) => {
        if (userAttributes) {
          fetchOrCreateUser({
            email: userAttributes.email,
            firstName: userAttributes.given_name,
            lastName: userAttributes.family_name,
          }).then((userDbResult) => {
            if (localStorage.getItem("user_id")) {
              fetchTripsForUser({
                userId: localStorage.getItem("user_id"),
              }).then((trips) => {
                if (trips) {
                  setTrips(trips);
                }
              });
            }
          });
        } else {
          handleSignOut();
        }
      });
    } else {
      //fetch trips only
      fetchTripsForUser({ userId: localStorage.getItem("user_id") }).then(
        (trips) => {
          if (trips) {
            setTrips(trips);
          }
        }
      );
    }

    // add delay due to race condition associated with social login provider
    setTimeout(() => {
      // handle routing
      const { pathname, search } = location;

      // only redirect when there's user and from sign in
      if (user && pathname === "/") {
        const urlParams = new URLSearchParams(search);
        // for explore or breaks b/c redirect_url only consists of "/explore" not query, lat, lng
        // seems to work well enough for now based on localStorage
        // TO DO make more robust
        const redirectUrl =
          localStorage.getItem("redirect_url") || urlParams.get("redirect_url");

        // for feature flag url setting to work, needs to be before checking for localStorage
        const geotaggingEnabled =
          urlParams.get("enable_geotagging") ||
          localStorage.getItem("enable_geotagging");

        setLocalStorageItem({
          key: "enable_geotagging",
          value: geotaggingEnabled,
        });

        if (redirectUrl) {
          navigate(redirectUrl);
          localStorage.removeItem("redirect_url");
        } else {
          navigate("/home");
        }
      }
    }, 1500); // needed to increment to for PublicPageWrapper to work w/ redirect
  }

  // TO DO investigate alternative way for keeping track of referrer url without using local storage
  useEffect(() => {
    logPageView({
      userId: localStorage.getItem("user_id"),
      sessionId: localStorage.getItem("session_id"),
      url: window.location.href,
      referrerUrl:
        window.location.href === localStorage.getItem("referrer_url")
          ? null
          : localStorage.getItem("referrer_url"),
    });
    setLocalStorageItem({ key: "referrer_url", value: window.location.href });

    try {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: window.location.pathname.substring(1),
      });
    } catch (e) {
      console.log({ e });
    }
  }, [location]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppContext.Provider
        value={{
          places,
          setPlaces,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          events,
          setEvents,
          tripTitle,
          setTripTitle,
          setTrips,
          trips,
          handleSignOut,
          userId: localStorage.getItem("user_id"),
          collaborators,
          setCollaborators,
          tripLocation,
          setTripLocation,
          eventFileExtension,
          setEventFileExtension,
          eventFileStr,
          setEventFileStr,
          showSnackbar,
          setShowSnackbar,
        }}
      >
        <ThemeProvider theme={Theme}>
          <ConfirmProvider>
            <div className="App">
              <HeaderAppBar handleSignOut={handleSignOut} />
              <Container sx={{ pt: "20px", pb: "70px" }}>
                {<Outlet />}
              </Container>
              <FooterAppBar />
            </div>
          </ConfirmProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </LocalizationProvider>
  );
};
