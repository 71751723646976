import { TablePagination } from "@mui/material";

export const TripsPagination = ({
  component,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  rows,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component={component}
      rowsPerPageOptions={[6, 12, 24]}
      count={rows && rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      slotProps={{
        select: {
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        },
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
