import { useState, useContext, useEffect } from "react";
import { AppContext } from "../contexts";
import {
  IconButton,
  ListItemIcon,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ShareIcon from "@mui/icons-material/Share";
import IosShareIcon from "@mui/icons-material/IosShare";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import PublicIcon from "@mui/icons-material/Public";
import { useParams, useNavigate } from "react-router-dom";
import { CopyToClipboardButton } from "./Buttons";
import {
  CopyShareTripTooltip,
  CopyURLConfirmMessage,
  NavigateToShareTripTooltip,
  TripVisibilityToggleExplanation,
  PublicTripTooltip,
  PrivateTripTooltip,
} from "../constants";
import { toggleTripPublic } from "../utils/trip_utils";

// TO DO dry this up??
export const TripFieldsPublic = () => {
  const { trip_id } = useParams();
  const navigate = useNavigate();
  const { trips, setTrips } = useContext(AppContext);

  const [isPublic, setIsPublic] = useState(false);
  const [shareUrlLabel, setShareUrlLabel] = useState("Share URL");
  const shareUrl = `${window.location.origin}/trip/share/${trip_id}`;

  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      const trip = trips.find((trips) => trips.id === trip_id);
      if (trip) {
        setIsPublic(trip.is_public);
      }
    }
  }, [trips, trip_id]);

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    setIsPublic(event.target.checked);
    toggleTripPublic({ id: trip_id, isPublic: event.target.checked }).then(
      (result) => {
        if (result) {
          const matchingIndex = trips.findIndex(
            (trips) => trips.id === trip_id
          );
          let tripsCopy = [...trips];
          tripsCopy[matchingIndex].is_public = event.target.checked;
          setTrips(tripsCopy);
        }
      }
    );
  };

  return (
    <Stack direction="column" spacing={1} mt={2}>
      <Stack direction="row">
        <Typography
          align="left"
          variant="body2"
          sx={{ color: "text.secondary" }}
        >
          {TripVisibilityToggleExplanation}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        mt={1}
      >
        {isPublic ? <PublicIcon /> : <PublicOffIcon />}
        <Typography>{isPublic ? "Public" : "Private"}</Typography>
        <Switch
          edge="end"
          onChange={handleChange}
          checked={isPublic}
          inputProps={{
            "aria-labelledby": "share-switch",
          }}
        />

        <Tooltip title={isPublic ? PublicTripTooltip : PrivateTripTooltip}>
          <ListItemIcon>
            <InfoIcon fontSize="sm" />
          </ListItemIcon>
        </Tooltip>
      </Stack>
      {isPublic ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <TextField
            id="text-field-public-trip-url"
            label={shareUrlLabel}
            value={shareUrl}
            size="small"
            fullWidth={true}
            inputProps={{
              readOnly: true,
            }}
            onDoubleClick={(e) => {
              navigator.clipboard.writeText(shareUrl);
              setShareUrlLabel("Copied!");
              setTimeout(() => {
                setShareUrlLabel("Share URL");
              }, [2000]);
            }}
          />
          <CopyToClipboardButton
            url={`${window.location.origin}/trip/share/${trip_id}`}
            icon={<IosShareIcon />}
            confirmMessage={CopyURLConfirmMessage}
            tooltipTitle={CopyShareTripTooltip}
          />
          <Tooltip title={NavigateToShareTripTooltip}>
            <IconButton onClick={() => navigate(`/trip/share/${trip_id}`)}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        ""
      )}
    </Stack>
  );
};
