import { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { utcDate } from "../utils/date_utils";
import {
  convertToBase64,
  uploadFile,
  deleteFile,
} from "../utils/storage_utils";
import { LocationChip, TripFileDisplay, TripCardActions } from "./";
import { CropPhotoDialog } from "./Modals";
import { useNavigate } from "react-router-dom";
import {
  FileNotFoundMessage,
  ExploreShareTripLocationTooltip,
} from "../constants";

export const TripCard = ({
  actions,
  trip,
  trips,
  setTrips,
  callee,
  tripsCoverPhotos,
  setTripCoverPhotos,
  page,
  setPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [cropPhotoDialogOpen, setCropPhotoDialogOpen] = useState(false);

  const currentUserId = localStorage.getItem("user_id");
  const currentUserEmail = localStorage.getItem("email");

  const currentUserIsOwner = currentUserId === trip.user_id;
  let currentUserIsCollaborator = trip.collaborators?.filter((c) => {
    return c.email === currentUserEmail || false;
  });
  // edge case empty collaboarator array would pass checkes for currentUserIsCollaborator
  if (currentUserIsCollaborator && currentUserIsCollaborator.length === 0)
    currentUserIsCollaborator = false;

  const SelectFileHandler = ({ file }) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setCropPhotoDialogOpen(true);
        setLoading(false);
        setSelectedFile({ name: file.name, type: file.type });
      });
      reader.readAsDataURL(file);
    }
  };

  const UploadHandler = ({ file }) => {
    const uploadFailureTimeout = setTimeout(() => {
      setUploadAlert({
        severity: "error",
        message: "Something went wrong. Try again.",
      });
      setLoading(false);
    }, 30000);
    cropPhotoDialogOpen && setCropPhotoDialogOpen(false);
    uploadFile({
      file,
      id: trip.id,
      bucket: "trips",
    }).then((response) => {
      if (response.status === 500) {
        setUploadAlert({
          severity: "error",
          message: response.message,
        });
      } else if (response.status === 200) {
        let tripsCoverPhotosCopy = { ...tripsCoverPhotos };
        tripsCoverPhotosCopy[trip.id] = {
          ...response,
          base64: convertToBase64(response),
        };
        setTripCoverPhotos(tripsCoverPhotosCopy);
      }
      setLoading(false);
      clearTimeout(uploadFailureTimeout);
    });
  };

  const DeleteHandler = () => {
    deleteFile({ id: trip.id, bucket: "trips" }).then((response) => {
      if (response) {
        let tripsCoverPhotosCopy = { ...tripsCoverPhotos };
        tripsCoverPhotosCopy[trip.id] = { message: FileNotFoundMessage };
        setTripCoverPhotos(tripsCoverPhotosCopy);
        setLoading(false);
      }
    });
  };

  let tripFile = "loading";
  // add additional checks due to race condition
  if (
    tripsCoverPhotos &&
    tripsCoverPhotos[trip.id] &&
    tripsCoverPhotos[trip.id].message === FileNotFoundMessage
  ) {
    tripFile = tripsCoverPhotos[trip.id].message;
  } else if (
    tripsCoverPhotos &&
    tripsCoverPhotos[trip.id] &&
    !tripsCoverPhotos[trip.id].error
  ) {
    tripFile = tripsCoverPhotos[trip.id];
  }

  const chipClickable = callee === "profile" ? true : false;

  return (
    trip && (
      <>
        <Card
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (callee === "home") {
              navigate(`/trip/${trip.id}`);
            } else if (callee === "profile" || "explore") {
              navigate(`/trip/share/${trip.id}`);
            }
          }}
        >
          <TripFileDisplay
            tripFile={tripFile}
            selectFileHandler={SelectFileHandler}
            loading={loading}
            setLoading={setLoading}
            alert={uploadAlert}
            setAlert={setUploadAlert}
            currentUserIsEditor={
              currentUserIsOwner || currentUserIsCollaborator
            }
            deleteHandler={DeleteHandler}
            styleOverrides={{
              aspectRatio: "3/1", // needs to be same as cover photo aspect ratio
              width: "100%",
              height: "auto",
            }}
          />
          <CardContent sx={{ padding: theme.spacing(1) }}>
            <Stack direction="column" spacing={1} justifyContent="flex-start">
              <Typography variant="h6" noWrap={true}>
                {trip.title}
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="flex-start">
                <LocationChip
                  tooltipTitle={
                    chipClickable ? ExploreShareTripLocationTooltip : ""
                  }
                  label={trip.location}
                  url={`/explore?query=${encodeURIComponent(
                    trip.location
                  )}&lat=${trip.lat}&lng=${trip.lng}`}
                  clickable={chipClickable}
                />
              </Stack>
              {/* TO DO make dates more useful in explore context?? */}
              <Typography variant="body1" noWrap={true}>{`${utcDate(
                trip.start_date
              ).format("M/D/YYYY")} -
              ${utcDate(trip.end_date).format("M/D/YYYY")}`}</Typography>
            </Stack>
          </CardContent>
          <TripCardActions
            trip={trip}
            trips={trips}
            actions={actions}
            setTrips={setTrips}
            callee={callee}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            currentUserIsOwner={currentUserIsOwner}
            currentUserIsCollaborator={currentUserIsCollaborator}
          />
          {/* 
            edge case for when trip doesn't have a cover photo and current viewer is not owner or collaborator
            render placeholder grid item to make card height uniform
          */}
          {tripFile === FileNotFoundMessage &&
          !(currentUserIsOwner || currentUserIsCollaborator) ? (
            <Grid
              item
              sx={{
                aspectRatio: "3/1", // needs to be same as cover photo aspect ratio
                width: "100%",
                height: "auto",
              }}
            />
          ) : (
            ""
          )}
          <CardActions></CardActions>
        </Card>

        {cropPhotoDialogOpen && imgSrc ? (
          <CropPhotoDialog
            imgSrc={imgSrc}
            open={cropPhotoDialogOpen}
            handleClose={() => setCropPhotoDialogOpen(false)}
            selectedFile={selectedFile}
            uploadHandler={UploadHandler}
            loading={loading}
            setLoading={setLoading}
            circularCrop={false}
            aspectRatio={3 / 1}
          />
        ) : (
          ""
        )}
      </>
    )
  );
};
