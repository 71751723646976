import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { IconButton, Tooltip } from "@mui/material";
import { EmojiPickerButtonTooltip } from "../../constants";

export const EmojiPickerButton = ({ onClick }) => {
  return (
    <Tooltip title={EmojiPickerButtonTooltip}>
      <IconButton
        aria-label="Edit"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation();
          onClick({ event });
        }}
      >
        <EmojiEmotionsIcon />
      </IconButton>
    </Tooltip>
  );
};
