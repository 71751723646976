import { Button, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  CopyTripIconButtonTooltip,
  CopyTripIconButtonTooltipSignedOut,
} from "../../constants";

export const CopyTripButton = ({ disabled, setCopyTripDialogOpen }) => {
  return (
    <Tooltip
      title={
        disabled
          ? CopyTripIconButtonTooltipSignedOut
          : CopyTripIconButtonTooltip
      }
    >
      <span>
        <Button
          disabled={disabled}
          aria-label="Copy"
          onClick={(event) => {
            event.stopPropagation();
            setCopyTripDialogOpen(true);
          }}
          endIcon={<ContentCopyIcon />}
          variant="contained"
        >
          Copy Trip
        </Button>
      </span>
    </Tooltip>
  );
};
