import {
  ErrorPage,
  Explore,
  Home,
  Trip,
  ShareTrip,
  Profile,
  PublicPageWrapper,
  Terms,
} from "./Components";
import { AuthWrapper } from "./AuthWrapper";
import { Navigate } from "react-router-dom";

export const routesConfig = [
  {
    path: "/",
    element: <AuthWrapper />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/trip/:trip_id",
        element: <Trip />,
      },
    ],
  },
  // public routes
  // TO DO refactor this logic
  // since these files have to recreate what's in App.js
  // but cannot have access to app context with the way things are currently configured
  {
    path: "/trip/share/:trip_id",
    element: <PublicPageWrapper children={<ShareTrip />} />,
  },
  {
    path: "/profile/:user_id",
    element: <PublicPageWrapper children={<Profile />} />,
  },
  {
    path: "/explore",
    element: <PublicPageWrapper children={<Explore />} />,
  },
  {
    path: "/copyrightpolicy",
    element: <PublicPageWrapper children={<Terms />} />,
  },
  {
    path: "/privacypolicy",
    element: <PublicPageWrapper children={<Terms />} />,
  },
  {
    path: "/termsofuse",
    element: <PublicPageWrapper children={<Terms />} />,
  },
  {
    path: "*", //catch all
    element: <Navigate to="/" replace />,
  },
];
