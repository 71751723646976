import { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { MenuBar } from "./MenuBar";
import { EditorContent, useEditor } from "@tiptap/react";
import { TextEditorDialog } from "../Modals";
import "./styles.css";
import { isJsonString } from "../../utils/text_utils";
import { extensions } from "../../utils/tiptap_utils";

export const TipTapWrappers = ({
  content,
  setContent,
  loading,
  setLoading,
  tripId,
  currentUserIsEditor,
}) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const isJson = isJsonString(content);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // https://github.com/ueberdosis/tiptap/issues/1883
  // https://codesandbox.io/p/sandbox/prod-cherry-b47hb?file=%2Fsrc%2FApp.js
  const editorProps = {
    content: isJson ? JSON.parse(content) : content,
    extensions,
  };

  const a = useEditor(editorProps);
  const b = useEditor({ ...editorProps, editable: false });

  const onUpdate = (a) => {
    setContent(JSON.stringify(a.editor.getJSON()));
  };

  a.on("update", onUpdate);

  useEffect(() => {
    if (dialogOpen) {
      a.commands.focus("end");
    }
  }, [dialogOpen]);

  const isEmpty = b.state.doc.textContent.length === 0;

  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        cursor: currentUserIsEditor ? "pointer" : "normal",
        borderRadius: theme.spacing(0.5),
        border: `1px solid transparent`,
        "&:hover": {
          border: `1px solid ${
            currentUserIsEditor ? theme.palette.grey["400"] : "transparent"
          }`,
        },
      }}
      mt={2}
    >
      <TextEditorDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        children={
          <div className="tiptap">
            <MenuBar editor={a} />
            <EditorContent editor={a} autoFocus={true} />
          </div>
        }
        content={content}
        tripId={tripId}
        loading={loading}
        setLoading={setLoading}
        b={b}
        a={a}
      />

      {/* 
      always want to show description when currentUserIsEditor
      otherwise only when it has content for non editor
      */}
      {currentUserIsEditor || !isEmpty ? (
        <EditorContent
          editor={b}
          onClick={() => {
            if (currentUserIsEditor) {
              setDialogOpen(true);
            }
          }}
          style={{ width: "100%" }}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};
