import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  StepLabel,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { TripFields1 } from "../";
import {
  NewTripDialogStep3Explanation,
  TripVisibilityToggleExplanation,
} from "../../constants";

export const NewTripDialogStep3Contents = ({
  geotaggingEnabled,
  title,
  setTitle,
  location,
  setLocation,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setLat,
  setLng,
  callee,
  loading,
  activeStep,
  setActiveStep,
  disabled,
  isPublic,
  setIsPublic,
}) => {
  return (
    <>
      {geotaggingEnabled ? (
        <StepLabel
          onClick={() => {
            !disabled && setActiveStep(1);
          }}
          disabled={loading}
        >
          {NewTripDialogStep3Explanation}
        </StepLabel>
      ) : (
        ""
      )}

      {activeStep === 2 ? (
        <Grid item xs={12} mt={2}>
          <TripFields1
            title={title}
            setTitle={setTitle}
            location={location}
            setLocation={setLocation}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setLat={setLat}
            setLng={setLng}
            callee={callee}
            setActiveStep={setActiveStep}
            loading={loading}
          />

          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
            mt={2}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setIsPublic(!isPublic)}
                  defaultChecked={true}
                />
              }
              label="Make public"
              disabled={loading}
            />
            <Tooltip title={TripVisibilityToggleExplanation}>
              <InfoIcon fontSize="sm" sx={{ marginLeft: "auto" }} />
            </Tooltip>
          </Stack>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
