import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import { EditTripButtonTooltip } from "../../constants";

export const EditTripButton = ({ trip }) => {
  let navigate = useNavigate();

  return (
    <Tooltip title={EditTripButtonTooltip}>
      <IconButton
        aria-label="Edit"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation();
          navigate(`/trip/${trip.id}`);
        }}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};
