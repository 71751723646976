import { CardActions, Chip } from "@mui/material";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import IosShareIcon from "@mui/icons-material/IosShare";
import { TripActionsMenu } from "./";
import {
  CopyToClipboardButton,
  EditTripButton,
  ShareTripButton,
} from "./Buttons";
import { CopyURLConfirmMessage, CopyShareTripTooltip } from "../constants";
import { CollaboratorChip } from "./CollaboratorChip";

export const TripCardActions = ({
  actions,
  trip,
  trips,
  setTrips,
  callee,
  page,
  setPage,
  rowsPerPage,
  currentUserIsCollaborator,
  currentUserIsOwner,
}) => {
  return (
    <CardActions>
      {/* only show chips from home or profile */}
      {callee === "home" || callee === "profile" ? (
        <>
          <Chip
            label={trip.is_collaborator ? "Collaborator" : "Owner"}
            icon={trip.is_collaborator ? <PeopleIcon /> : <PersonIcon />}
            sx={{ cursor: "default" }}
          />
          <Chip
            label={trip.is_public ? "Public" : "Private"}
            icon={trip.is_public ? <PublicIcon /> : <PublicOffIcon />}
            sx={{ cursor: "default" }}
          />
        </>
      ) : (
        <CollaboratorChip
          collaborator={
            trip.collaborators && trip.collaborators.find((c) => c.tripOwner)
          }
          index={0}
          handleDeleteCollaborator={null}
          key={`collaborator-chip-0`}
          tooltipTitle={"Trip owner"}
        />
      )}
      {/* always show if permission */}
      {currentUserIsOwner || currentUserIsCollaborator ? (
        <EditTripButton trip={trip} />
      ) : (
        ""
      )}

      {/* 
      show menu from home or profile
      explore should have buttons directly
      */}
      {callee === "home" || callee === "profile" ? (
        <TripActionsMenu
          trip={trip}
          actions={actions}
          trips={trips}
          setTrips={setTrips}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          currentUserIsOwner={currentUserIsOwner}
          currentUserIsCollaborator={currentUserIsCollaborator}
        />
      ) : (
        <>
          <ShareTripButton trip={trip} />

          <CopyToClipboardButton
            url={`${window.location.origin}/trip/share/${trip.id}`}
            icon={<IosShareIcon />}
            confirmMessage={CopyURLConfirmMessage}
            tooltipTitle={CopyShareTripTooltip}
          />
        </>
      )}
    </CardActions>
  );
};
