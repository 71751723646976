import { ThemeProvider as AmplifyThemeProvider } from "@aws-amplify/ui-react";
import { withStyledAuthenticator, ScrollToTop } from "./Components";
import { App } from "./App";
const StyledAuthenticator = withStyledAuthenticator(App);

export const AuthWrapper = () => {
  return (
    <AmplifyThemeProvider>
      {/* TO DO refactor to hook */}
      <ScrollToTop />
      <StyledAuthenticator />
    </AmplifyThemeProvider>
  );
};
