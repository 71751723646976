import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useTheme,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextEditorDialogTitle } from "./";
import { SaveTripDescriptionButton } from "../Buttons";
export const TextEditorDialog = ({
  open,
  handleClose,
  children,
  content,
  tripId,
  loading,
  setLoading,
  b,
  a,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const confirm = useConfirm();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        const aContent = a.getJSON();
        const bContent = b.getJSON();

        if (
          JSON.stringify(aContent) !== JSON.stringify(bContent) &&
          reason === "backdropClick"
        ) {
          confirm({
            title: "Confirm",
            description: "Any changes will not be saved!",
            confirmationButtonProps: { variant: "contained" },
          })
            .then(() => {
              handleClose();
              // revert content of editor a to b
              a.commands.setContent(bContent);
            })
            .catch((e) => {
              // console.log({ e });
            });
        } else {
          handleClose();
        }
      }}
      fullScreen={fullScreen}
      fullWidth={true}
      PaperProps={{
        component: "form",
      }}
    >
      <TextEditorDialogTitle
        title="Add Description"
        handleClose={handleClose}
        loading={loading}
      />
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SaveTripDescriptionButton
          tripId={tripId}
          content={content}
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          b={b}
        />
      </DialogActions>
    </Dialog>
  );
};
