import ReactGA from "react-ga4";
import React, { useEffect } from "react";
import { HeaderAppBar, FooterAppBar, HeaderAppBarPublic } from "./Layout";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Theme } from "../Theme";
import { logPageView } from "../utils/view_utils";
import { useLocation } from "react-router-dom";
import { setLocalStorageItem } from "../utils/user_utils";

export const PublicPageWrapper = ({ children }) => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

  const hasSession =
    localStorage.getItem("first_name") &&
    localStorage.getItem("last_name") &&
    localStorage.getItem("user_id") &&
    localStorage.getItem("email")
      ? true
      : false;
  const location = useLocation();

  // TO DO investigate alternative way for keeping track of referrer url without using local storage
  useEffect(() => {
    logPageView({
      userId: localStorage.getItem("user_id"),
      sessionId: localStorage.getItem("session_id"),
      url: window.location.href,
      referrerUrl:
        window.location.href === localStorage.getItem("referrer_url")
          ? null
          : localStorage.getItem("referrer_url"),
    });
    setLocalStorageItem({ key: "referrer_url", value: window.location.href });

    try {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: window.location.pathname.substring(1),
      });
    } catch (e) {
      console.log({ e });
    }
  }, [location]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={Theme}>
        <ConfirmProvider>
          <div className="App">
            {hasSession ? <HeaderAppBar /> : <HeaderAppBarPublic />}
            <Container sx={{ pt: "20px", pb: "70px" }}>{children}</Container>
            <FooterAppBar />
          </div>
        </ConfirmProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
