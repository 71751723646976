import EmojiPicker from "emoji-picker-react";
import { Menu } from "@mui/material";
export const EmojiPickerMenu = ({
  emojiPickerOpen,
  anchorEl,
  onClose,
  onSelection,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={emojiPickerOpen}
      onClose={() => {
        onClose();
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "hidden",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <EmojiPicker style={{ border: "none" }} onEmojiClick={onSelection} />
    </Menu>
  );
};
