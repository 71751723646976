import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { CloseModal } from "./index.js";
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../Crop/useDebounceEffect.ts";
import { Loader } from "../";

// this code is a combines the approaches from the two following examples
// 1) https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FApp.tsx
// 2) https://levelup.gitconnected.com/react-image-upload-with-cropping-resizing-and-uploading-to-aws-s3-447b27ae6acb
export const CropPhotoDialog = ({
  imgSrc,
  open,
  handleClose,
  selectedFile,
  uploadHandler,
  loading,
  setLoading,
  circularCrop,
  aspectRatio,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [aspect] = useState<number | undefined>(aspectRatio || 1);
  const [cropResult, setCropResult] = useState<File | null>(null);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const getCroppedImage = () => {
    const image = imgRef.current;
    if (image && crop) {
      const cropWidth = image.naturalWidth * (crop.width / 100);
      const cropHeight = image.naturalHeight * (crop.height / 100);
      const startX = image.naturalWidth * (crop.x / 100);
      const startY = image.naturalHeight * (crop.y / 100);

      const canvas = document.createElement("canvas");
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      const ctx = canvas.getContext("2d");

      ctx &&
        ctx.drawImage(
          image,
          startX,
          startY,
          cropWidth,
          cropHeight,
          0,
          0,
          cropWidth,
          cropHeight
        );

      const reader = new FileReader();
      canvas.toBlob(
        (blob) => {
          if (blob) {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              dataURLtoFile(reader.result, selectedFile.name);
            };
          }
        },
        selectedFile.type,
        1
      );
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    croppedImage && setCropResult(croppedImage);
  };

  useDebounceEffect(
    async () => {
      // once we saved our crop, we now need to process this new image size
      if (imgRef.current && crop && crop.width && crop.height) {
        getCroppedImage();
      }
    },
    100,
    [crop, getCroppedImage]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={true}
      PaperProps={{
        component: "form",
      }}
    >
      <CropPhotoDialogTitle handleClose={handleClose} loading={loading} />
      <DialogContent>
        <Box>
          {loading ? (
            <Loader styleOverrides={{ backgroundColor: "transparent" }} />
          ) : (
            ""
          )}
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              aspect={aspect}
              circularCrop={circularCrop}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setLoading(true);
            cropResult && uploadHandler({ file: cropResult });
          }}
          disabled={loading}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CropPhotoDialogTitle = ({ handleClose, loading }) => {
  return (
    <Grid
      container
      sx={{
        alignItems: "center",
      }}
    >
      <Grid item>
        <DialogTitle>Crop Photo</DialogTitle>
      </Grid>
      {handleClose ? (
        <CloseModal
          handleClose={() => {
            handleClose();
          }}
          loading={loading}
          style={{}}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
