import { useState } from "react";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { fetchTripsForUser, deleteTrip } from "../utils/trip_utils";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ShareTripMenu } from "./";

export const TripActionsMenu = ({
  trip,
  actions,
  trips,
  setTrips,
  page,
  setPage,
  rowsPerPage,
  currentUserIsOwner,
  currentUserIsCollaborator,
}) => {
  let navigate = useNavigate();
  const confirm = useConfirm();

  const currentUserId = localStorage.getItem("user_id");

  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const actionMenuOpen = Boolean(actionMenuAnchorEl);

  const handleActionMenuClick = (event) => {
    event.stopPropagation();
    setActionMenuAnchorEl(event.currentTarget);
  };
  const handleActionMenuClose = (event) => {
    event.stopPropagation();
    setActionMenuAnchorEl(null);
  };
  return (
    trip && (
      <div>
        <Tooltip title="More actions">
          <IconButton
            aria-label="more"
            id="basic-button"
            aria-controls={actionMenuOpen ? "basic-menu" : undefined}
            aria-expanded={actionMenuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleActionMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={actionMenuAnchorEl}
          open={actionMenuOpen}
          onClose={handleActionMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* only action that is allowed from home and profile tables for users that are owner or collaborator */}
          {actions.includes("edit") &&
            (currentUserIsOwner || currentUserIsCollaborator) && (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleActionMenuClose(event);
                  navigate(`/trip/${trip.id}`);
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            )}
          <ShareTripMenu
            trip={trip}
            trips={trips}
            setTrips={setTrips}
            actions={actions}
            handleActionMenuClose={handleActionMenuClose}
            // if currentUser is owner or collaborator and actions include edit, can assume divider is needed for share menu
            renderDivider={
              (currentUserIsOwner || currentUserIsCollaborator) &&
              actions.includes("edit")
            }
          />
          {/* 
          only on home screen for trip owner, always has divider before
          */}
          {actions.includes("delete") && currentUserIsOwner && (
            <MenuList>
              <Divider />
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleActionMenuClose(event);
                  confirm({
                    title: "Confirm",
                    description: "This action is permanent!",
                    confirmationButtonProps: { variant: "contained" },
                  })
                    .then(() => {
                      deleteTrip({ tripId: trip.id })
                        .then((response) => response.json())
                        .then((result) => {
                          if (result) {
                            fetchTripsForUser({ userId: currentUserId }).then(
                              (trips) => {
                                // https://github.com/mui/material-ui/issues/15616#issuecomment-1196670661
                                const maxPage =
                                  Math.floor(trips.length / rowsPerPage) - 1;
                                if (page >= maxPage && maxPage >= 0) {
                                  setPage && setPage(maxPage);
                                }
                                setTrips && setTrips(trips);
                                handleActionMenuClose(event);
                              }
                            );
                          }
                        });
                    })
                    .catch(() => {});
                }}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </div>
    )
  );
};
