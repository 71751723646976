import { AppBar, Toolbar, Stack, Link } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

export const FooterAppBar = () => {
  const navigate = useNavigate();
  const linkStyles = {
    color: grey[400],
    textDecoration: "none",
    fontSize: "12px",
  };
  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0, background: grey[100] }}
    >
      <Toolbar>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Link
            component="button"
            onClick={() => {
              navigate("termsofuse");
            }}
            sx={{ ...linkStyles }}
          >
            Terms
          </Link>
          <Link
            component="button"
            onClick={() => {
              navigate("/privacypolicy");
            }}
            sx={{ ...linkStyles }}
          >
            Privacy Policy
          </Link>
          <Link
            component="button"
            onClick={() => {
              navigate("/copyrightpolicy");
            }}
            sx={{ ...linkStyles }}
          >
            Copyright &copy;
          </Link>
          {/* 41 upvoted answer here
          https://stackoverflow.com/questions/63782544/react-open-mailto-e-mail-client-onclick-with-body-from-textarea */}
          <Link
            component="button"
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:bode@trippyapp.xyz";
              e.preventDefault();
            }}
            sx={{ ...linkStyles }}
          >
            Contact
          </Link>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
