import {
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Dropzone } from "./Dropzone";
import {
  allowedAiEventFileInfo,
  DropzoneLabel,
  DropzoneExplanation1,
  NewTripSoloTravelerExplanation,
} from "../constants";
export const UploadPhotosGenerateEventsDetails = ({
  uploadedFiles,
  setUploadedFiles,
  soloTraveler,
  setSoloTraveler,
  loading,
  setTripAlerts,
  paths,
  setPaths,
  location,
  setLocation,
  setStartDate,
  setEndDate,
  setLat,
  setLng,
}) => {
  return (
    <Stack direction="column" spacing={2}>
      <Dropzone
        allowedFileInfo={allowedAiEventFileInfo}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        setAlerts={setTripAlerts}
        loading={loading}
        explanation={
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "0.75rem",
            }}
          >
            {DropzoneExplanation1}
          </Typography>
        }
        label={
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "0.875rem",
            }}
          >
            {DropzoneLabel}
          </Typography>
        }
        maxFiles={15}
        paths={paths}
        setPaths={setPaths}
        location={location}
        setLocation={setLocation}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setLat={setLat}
        setLng={setLng}
      />

      <Stack
        direction="row"
        spacing={1}
        alignContent="center"
        alignItems="center"
      >
        <FormControlLabel
          control={<Checkbox onChange={() => setSoloTraveler(!soloTraveler)} />}
          label="Solo traveler"
          disabled={loading}
        />
        <Tooltip title={NewTripSoloTravelerExplanation}>
          <InfoIcon fontSize="sm" sx={{ marginLeft: "auto" }} />
        </Tooltip>
      </Stack>
    </Stack>
  );
};
