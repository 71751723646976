import { useState } from "react";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandMoreButton } from "./Buttons";
import { ShowMoreTooltip, ShowLessTooltip } from "../constants";

export const WithCollapse = ({ collapse, children, collapsedSize }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return collapse ? (
    <>
      <Collapse
        in={expanded}
        collapsedSize={collapsedSize}
        sx={{ textAlign: "left" }}
      >
        {children}
      </Collapse>
      <ExpandMoreButton
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label={expanded ? ShowLessTooltip : ShowMoreTooltip}
        title={expanded ? ShowLessTooltip : ShowMoreTooltip}
      >
        <ExpandMoreIcon />
      </ExpandMoreButton>
    </>
  ) : (
    children
  );
};
