import { useState } from "react";
import {
  Divider,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Switch,
} from "@mui/material";
import { toggleTripPublic } from "../utils/trip_utils";
import { useNavigate } from "react-router-dom";
import IosShareIcon from "@mui/icons-material/IosShare";
import ShareIcon from "@mui/icons-material/Share";
import PreviewIcon from "@mui/icons-material/Preview";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import PublicIcon from "@mui/icons-material/Public";
import InfoIcon from "@mui/icons-material/Info";
import { TripVisibilityToggleExplanation } from "../constants";

export const ShareTripMenu = ({
  trip,
  trips,
  setTrips,
  actions,
  handleActionMenuClose,
  renderDivider,
}) => {
  let navigate = useNavigate();

  const [copiedText, setCopiedText] = useState("Copy URL");

  return (
    <MenuList>
      {renderDivider && <Divider />}
      {/* 
        only on home screen since toggle action only included on homes trip table
        allowed by trip owners and collaborators
        */}
      <TripVisibilityToggle
        actions={actions}
        trip={trip}
        trips={trips}
        setTrips={setTrips}
      />
      {trip.is_public && actions.includes("share") ? (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/trip/share/${trip.id}`);
            handleActionMenuClose(event);
          }}
        >
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
      ) : (
        ""
      )}
      {trip.is_public && actions.includes("preview") && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/trip/share/${trip.id}`);
            handleActionMenuClose(event);
          }}
        >
          <ListItemIcon>
            <PreviewIcon />
          </ListItemIcon>
          <ListItemText>Preview</ListItemText>
        </MenuItem>
      )}
      {trip.is_public && actions.includes("copy") && (
        <MenuItem
          onClick={(event) => {
            navigator.clipboard.writeText(
              `${window.location.origin}/trip/share/${trip.id}`
            );
            setCopiedText("Copied!");
            event.stopPropagation();
            setTimeout(() => {
              handleActionMenuClose(event);
              setCopiedText("Copy URL");
            }, 2000);
          }}
        >
          <ListItemIcon>
            <IosShareIcon />
          </ListItemIcon>
          <ListItemText>{copiedText}</ListItemText>
        </MenuItem>
      )}
    </MenuList>
  );
};

const TripVisibilityToggle = ({ actions, trip, trips, setTrips }) => {
  const [checked, setChecked] = useState(trip.is_public);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);

    toggleTripPublic({ id: trip.id, isPublic: event.target.checked }).then(
      (result) => {
        if (result) {
          const matchingIndex = trips.findIndex((t) => t.id === trip.id);
          let tripsCopy = [...trips];
          tripsCopy[matchingIndex].is_public = event.target.checked;
          setTrips(tripsCopy);
        }
      }
    );
  };
  return (
    actions.includes("toggle") && (
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ListItemIcon>
          {checked ? <PublicIcon /> : <PublicOffIcon />}
        </ListItemIcon>
        <ListItemText>{checked ? "Public" : "Private"}</ListItemText>
        <Switch
          edge="end"
          onChange={handleSwitchChange}
          checked={checked}
          inputProps={{
            "aria-labelledby": "share-switch",
          }}
        />
        <Tooltip title={TripVisibilityToggleExplanation}>
          <ListItemIcon>
            <InfoIcon fontSize="sm" sx={{ marginLeft: "auto" }} />
          </ListItemIcon>
        </Tooltip>
      </MenuItem>
    )
  );
};
