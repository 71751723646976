import { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext } from "../contexts";
import {
  EventFileButton,
  GoogleMapsAutocompleteWrapper,
  SuggestedLocationChip,
} from "./";
import {
  uploadFile,
  convertToBase64,
  deleteFile,
} from "../utils/storage_utils";
import { saveEvent, fetchEventsForTrip } from "../utils/event_utils";
import { SuggestedLocationsExplanation } from "../constants";

export const EventFields2 = ({
  loading,
  setLoading,
  title,
  startTime,
  endTime,
  type,
  setType,
  description,
  setDescription,
  setAlert,
  location,
  setLocation,
  setLat,
  setLng,
  newEvent,
  eventId,
  setFileDisplayBackdropOpen,
  tripLatLng,
  setEventClicked,
  setEventSelected,
  aiGenerated,
  suggestedLocations,
}) => {
  const { trip_id } = useParams();
  const { setEventFileStr, setEventFileExtension, setEvents, userId } =
    useContext(AppContext);

  // TO DO move to event_utils
  const UploadHandler = async ({ file }) => {
    // allow uploading of attachment for unsaved event
    // this works by triggering save first, then performing upload
    let savedEvent;
    if (newEvent) {
      savedEvent = await saveEvent({
        title,
        startTime,
        endTime,
        tripId: trip_id,
        userId: userId,
        description,
        id: null,
        location,
        lat: tripLatLng.lat,
        lng: tripLatLng.lng,
        type,
      });

      const updatedEvents = await fetchEventsForTrip({
        tripId: trip_id,
        callee: "edit",
      });

      // needs creator info
      const matchingEvent = updatedEvents.all.find((event) => {
        return event.id === savedEvent.id;
      });

      setEvents(updatedEvents);
      setEventClicked(matchingEvent);
      setEventSelected(undefined); // unsure about this but eventSelected was returning object so newEvent was defined when it shouldn't be
    }
    const uploadFailureTimeout = setTimeout(() => {
      setAlert({
        severity: "error",
        message: "Something went wrong. Try again.",
      });
      setLoading(false);
    }, 30000);
    uploadFile({
      file,
      id: eventId || savedEvent.id,
      bucket: "events",
    }).then((response) => {
      if (response) {
        if (response.status === 500) {
          setAlert({
            severity: "error",
            message: response.message,
          });
        } else if (response.status === 200) {
          setEventFileStr(convertToBase64(response));
          setEventFileExtension(response.extension);
        }
        setLoading(false);
        clearTimeout(uploadFailureTimeout);
      }
    });
  };

  const DeleteHandler = () => {
    deleteFile({ id: eventId, bucket: "events" }).then((response) => {
      if (response) {
        setEventFileStr("File not found"); //short circuit
        setEventFileExtension(undefined);
      }
    });
  };

  const geotaggingEnabled =
    localStorage.getItem("enable_geotagging") === "true";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GoogleMapsAutocompleteWrapper
          location={location}
          setLocation={setLocation}
          setLat={setLat}
          setLng={setLng}
          required={false}
          disabled={loading}
          tripLatLng={tripLatLng}
          label="Add a location"
          autoFocus={true}
        />
      </Grid>
      {geotaggingEnabled &&
      aiGenerated &&
      suggestedLocations.length &&
      !location ? (
        <Grid item xs={12}>
          <Typography
            component="div"
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "0.875rem",
            }}
          >
            {SuggestedLocationsExplanation}
          </Typography>
          {suggestedLocations.map((location, index) => {
            return (
              <SuggestedLocationChip
                key={encodeURIComponent(location)}
                onClick={() => setLocation(location)}
                clickable={true}
                label={location}
              />
            );
          })}
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={12}>
        <FormControl fullWidth={true}>
          <InputLabel id="text-field-type-label">Type</InputLabel>
          <Select
            labelId="text-field-type-label"
            value={type}
            label="Type"
            onChange={(e) => {
              setType(e.target.value);
            }}
            slotProps={{
              textField: { label: "Type" },
            }}
            disabled={loading ? true : false}
            id={"text-field-type"}
          >
            <MenuItem value="accommodation">Accommodation</MenuItem>
            <MenuItem value="activity">Activity</MenuItem>
            <MenuItem value="food">Food</MenuItem>
            <MenuItem value="transit">Transit</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Description"
          fullWidth={true}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          multiline
          rows={5}
          value={description}
          disabled={loading ? true : false}
          id={"text-field-description"}
        ></TextField>
      </Grid>
      <EventFileButton
        deleteHandler={DeleteHandler}
        uploadHandler={UploadHandler}
        loading={loading}
        setLoading={setLoading}
        setAlert={setAlert}
        setFileDisplayBackdropOpen={setFileDisplayBackdropOpen}
      />
    </Grid>
  );
};
