import { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GridView, MapView, ToggleViewBar, TripsTable } from "./";
import PlaceIcon from "@mui/icons-material/Place";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  allTripColumns,
  TripsGridTooltip,
  TripsMapTooltip,
  TripsTableTooltip,
} from "../constants";

import { retrieveCoverPhotos } from "../utils/trip_utils";

export const ExploreResults = ({ trips, setTrips, label }) => {
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("public_views");
  const [tripsView, setTripsView] = useState(
    localStorage.getItem("explore_view") || "grid"
  );
  const [tripsCoverPhotos, setTripCoverPhotos] = useState(undefined);

  const columnsForTable = allTripColumns.filter((column) =>
    ["title", "public views", "dates", "actions"].includes(column.value)
  );

  useEffect(() => {
    if (trips) {
      const tripIds = trips.map(({ id }) => {
        return {
          id,
        };
      });
      retrieveCoverPhotos({ tripIds }).then((coverPhotos) => {
        setTripCoverPhotos(coverPhotos);
      });
    }
  }, [trips]);

  return (
    <Grid container>
      {trips.length ? (
        <ToggleViewBar
          items={[
            {
              key: "grid",
              value: "grid",
              icon: <ViewModuleIcon />,
              tooltip: TripsGridTooltip,
            },
            {
              key: "map",
              value: "map",
              icon: <PlaceIcon />,
              tooltip: TripsMapTooltip,
            },
            {
              key: "table",
              value: "table",
              icon: <TableRowsIcon />,
              tooltip: TripsTableTooltip,
            },
          ]}
          view={tripsView}
          setView={setTripsView}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          title={<Typography variant="h6">{label}</Typography>}
          callee="explore"
        />
      ) : (
        ""
      )}

      {tripsView === "table" ? (
        <Grid container mt={2}>
          <TripsTable
            trips={trips}
            actions={["edit", "share", "copy"]}
            setTrips={setTrips}
            columns={columnsForTable}
            callee="explore"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
        </Grid>
      ) : tripsView === "grid" ? (
        <Grid container mt={2}>
          <GridView
            trips={trips}
            callee="explore"
            actions={["share", "copy"]}
            setTrips={setTrips}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            tripsCoverPhotos={tripsCoverPhotos}
            setTripCoverPhotos={setTripCoverPhotos}
          />
        </Grid>
      ) : (
        <Grid
          container
          mt={2}
          sx={{
            minHeight: screenIsSmall ? "400px" : "600px",
          }}
        >
          <MapView
            places={trips}
            callee="explore"
            styleOverrides={{
              height: screenIsSmall ? "400px" : "600px",
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
