import { useState, useEffect, forwardRef } from "react";
import {
  Alert,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Link,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FollowDialog } from "./Modals";
import { FollowUnfollowButton } from "./Buttons";
import { useLocation } from "react-router-dom";
import IosShareIcon from "@mui/icons-material/IosShare";
import { UploadAvatar } from "./";
import { CopyToClipboardButton } from "./Buttons";
import { CopyProfileTooltip, CopyURLConfirmMessage } from "../constants";
import { allowedAvatarFileInfo } from "../constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const ProfileSummary = forwardRef(
  (
    {
      profile,
      hasSession,
      profileFollowerCount,
      profileFollowingCount,
      profileFollowers,
      profileFollowing,
      currentUserFollowing,
      currentUserFollowers,
      topLevelFollowHandler,
      topLevelUnfollowHandler,
      dialogFollowHandler,
      dialogUnfollowHandler,
      dialogRemoveFollowerHandler,
      expanded,
      setExpanded,
      loading,
      setLoading,
      suggestedProfiles,
      dialogItems,
      setDialogItems,
      setProfileFollowers,
      setProfileFollowing,
      alert,
      setAlert,
      selectFileHandler,
      avatarFileStr,
      setAvatarFileStr,
      setAvatarFileExtension,
    },
    ref
  ) => {
    const location = useLocation();
    const currentUserId = localStorage.getItem("user_id");

    const [isFollower, setIsFollower] = useState(undefined);
    const [ownProfile, setOwnProfile] = useState(currentUserId === profile.id);
    const [dialogTitle, setDialogTitle] = useState();
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      setOwnProfile(currentUserId === profile.id);
      if (
        profileFollowers &&
        profileFollowers.executed &&
        profileFollowers.response
      ) {
        setIsFollower(
          profileFollowers.response.length &&
            profileFollowers.response.filter((f) => f.id === currentUserId)
              .length > 0
            ? true
            : false
        );
      } else {
        setIsFollower(undefined);
      }
    }, [profileFollowers, location, currentUserId, profile]);

    useEffect(() => {
      if (dialogTitle === "Followers") {
        setDialogItems(profileFollowers.response);
      } else if (dialogTitle === "Following") {
        setDialogItems(profileFollowing.response);
      } else if (dialogTitle === "Suggested Profiles") {
        setDialogItems(suggestedProfiles.response);
      }
    }, [
      dialogTitle,
      profileFollowers,
      profileFollowing,
      suggestedProfiles,
      setDialogItems,
    ]);

    return (
      <>
        <Accordion
          id="share-profile-accordion"
          ref={ref}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Typography>Profile</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              sx={{
                alignItems: "center",
              }}
            >
              {alert ? (
                <Grid item xs={12}>
                  <Alert
                    severity={alert.severity}
                    onClose={() => setAlert(false)}
                  >
                    {alert.message}
                  </Alert>
                </Grid>
              ) : (
                ""
              )}
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                {ownProfile &&
                !localStorage.getItem("current_user_avatar_url") ? (
                  <UploadAvatar
                    allowedFileInfo={allowedAvatarFileInfo}
                    selectFileHandler={selectFileHandler}
                    label={profile.first_name && profile.first_name[0]}
                    icon={
                      <AddCircleIcon
                        sx={{
                          height: "22px",
                          width: "22px",
                        }}
                      />
                    }
                    setAlert={setAlert}
                    setLoading={setLoading}
                  />
                ) : (
                  <Avatar
                    component="label"
                    variant="contained"
                    src={avatarFileStr}
                  >
                    {profile.first_name[0]}
                  </Avatar>
                )}
                <Typography variant="subtitle1" component="span">
                  {`${profile.first_name} ${profile.last_name}`}
                </Typography>
                <CopyToClipboardButton
                  url={`${window.location.origin}/profile/${profile.id}`}
                  icon={<IosShareIcon />}
                  confirmMessage={CopyURLConfirmMessage}
                  tooltipTitle={CopyProfileTooltip}
                />
              </Stack>
            </Grid>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              mt={1}
            >
              {profileFollowerCount > 0 ? (
                <Link
                  variant="subtitle1"
                  underline="none"
                  onClick={() => {
                    if (hasSession) {
                      setDialogTitle("Followers");
                      setDialogItems(profileFollowers.response);
                      handleClickOpen();
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {profileFollowerCount}{" "}
                  {profileFollowerCount === 1 ? "follower" : "followers"}
                </Link>
              ) : (
                <Typography variant="subtitle1" sx={{ cursor: "text" }}>
                  {profileFollowerCount}{" "}
                  {profileFollowerCount === 1 ? "follower" : "followers"}
                </Typography>
              )}
              {profileFollowingCount > 0 ? (
                <Link
                  variant="subtitle1"
                  underline="none"
                  onClick={() => {
                    if (hasSession) {
                      setDialogTitle("Following");
                      setDialogItems(profileFollowing.response);
                      handleClickOpen();
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {profileFollowingCount} following
                </Link>
              ) : (
                <Typography variant="subtitle1" sx={{ cursor: "text" }}>
                  {profileFollowingCount} following
                </Typography>
              )}
              <FollowUnfollowButton
                isFollower={isFollower}
                hasSession={hasSession}
                topLevelUnfollowHandler={topLevelUnfollowHandler}
                topLevelFollowHandler={topLevelFollowHandler}
                profile={profile}
                loading={loading}
                setLoading={setLoading}
                ownProfile={ownProfile}
                setDialogItems={setDialogItems}
                setDialogTitle={setDialogTitle}
                suggestedProfiles={suggestedProfiles}
                handleClickOpen={handleClickOpen}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>

        <FollowDialog
          open={open}
          onClose={handleClose}
          title={dialogTitle}
          items={dialogItems}
          setItems={setDialogItems}
          currentUserFollowing={currentUserFollowing}
          currentUserFollowers={currentUserFollowers}
          dialogFollowHandler={dialogFollowHandler}
          dialogUnfollowHandler={dialogUnfollowHandler}
          dialogRemoveFollowerHandler={dialogRemoveFollowerHandler}
          loading={loading}
          setLoading={setLoading}
          setProfileFollowers={setProfileFollowers}
          setProfileFollowing={setProfileFollowing}
        />
      </>
    );
  }
);
