import { CircularProgress, Typography } from "@mui/material";
import { EmptyState } from "./";

export const Loader = ({ styleOverrides, children, progress = {} }) => {
  const { value = null, label = null } = progress;
  return (
    <EmptyState
      styleOverrides={styleOverrides}
      children={
        children ? (
          children
        ) : (
          <>
            <CircularProgress sx={{ margin: "auto 0" }} />
            {label ? (
              <Typography
                component="p"
                sx={{
                  color: "rgba(0, 0, 0, 0.4)",
                  fontSize: "0.75rem",
                  textAlign: "center",
                }}
              >
                {label}
              </Typography>
            ) : (
              ""
            )}
          </>
        )
      }
    ></EmptyState>
  );
};
