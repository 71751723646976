import { Grid, Snackbar, Typography } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { AppContext } from "../contexts";
import { useContext, useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Loader } from "./";
import { retrieveCoverPhotos } from "../utils/trip_utils";
import { NewTripDialog } from "./Modals";
import { GridView, ToggleViewBar, TripsTable } from "./";
import { NewTripButton } from "./Buttons";
import {
  allTripColumns,
  TripsGridTooltip,
  TripsTableTooltip,
} from "../constants";

export const Home = () => {
  const {
    trips,
    setTrips,
    setStartDate,
    setEndDate,
    setTripTitle,
    setTripLocation,
    showSnackbar,
    setShowSnackbar,
  } = useContext(AppContext);

  const savedTripView =
    localStorage.getItem("trips_table_sort") &&
    JSON.parse(localStorage.getItem("trips_table_sort"))
      ? JSON.parse(localStorage.getItem("trips_table_sort"))
      : {
          order: "desc",
          orderBy: "last_modified_at",
        };
  const columnsForTable = allTripColumns.filter((column) =>
    ["title", "permission", "last modified", "dates", "actions"].includes(
      column.value
    )
  );
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [newTripDialogOpen, setNewTripDialogOpen] = useState(false);
  const [tripsView, setTripsView] = useState(
    localStorage.getItem("home_view") || "grid"
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState(savedTripView.order);
  const [orderBy, setOrderBy] = useState(savedTripView.orderBy);
  const [tripsCoverPhotos, setTripCoverPhotos] = useState(undefined);

  const handleNewTripDialogClose = () => {
    setNewTripDialogOpen(false);
    setTripTitle("");
    setStartDate("");
    setEndDate("");
    setTripLocation("");
    searchParams.delete("newtripdialog");
    searchParams.delete("location");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (trips) {
      const tripIds = trips.map(({ id }) => {
        return {
          id,
        };
      });
      retrieveCoverPhotos({ tripIds }).then((coverPhotos) => {
        setTripCoverPhotos(coverPhotos);
      });
    }
  }, [trips]);

  useEffect(() => {
    const { pathname, search } = location;
    if (pathname && search) {
      const urlParams = new URLSearchParams(search);
      const dialogParam = urlParams.get("newtripdialog");
      const locationParam = urlParams.get("location");
      if (dialogParam) {
        setNewTripDialogOpen(true);
        setSearchParams({
          newtripdialog: dialogParam,
          location: locationParam,
        });
        setTripLocation(locationParam);
      }
    }
  }, []);

  return trips ? (
    <Grid container>
      {trips.length ? (
        <ToggleViewBar
          items={[
            {
              key: "grid",
              value: "grid",
              icon: <ViewModuleIcon />,
              tooltip: TripsGridTooltip,
            },
            {
              key: "table",
              value: "table",
              icon: <TableRowsIcon />,
              tooltip: TripsTableTooltip,
            },
          ]}
          view={tripsView}
          setView={setTripsView}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          title={<Typography variant="h6">Your Trips</Typography>}
          callee="home"
        />
      ) : (
        ""
      )}

      <NewTripButton
        isFirstTrip={trips.length ? false : true}
        setNewTripDialogOpen={setNewTripDialogOpen}
      />

      <NewTripDialog
        open={newTripDialogOpen}
        handleClose={handleNewTripDialogClose}
        isFirstTrip={trips.length ? false : true}
      />

      {showSnackbar ? (
        <Snackbar
          open={showSnackbar}
          onClose={() => setShowSnackbar(false)}
          autoHideDuration={5000}
          message="Cannot access trip."
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      ) : (
        ""
      )}

      {trips.length ? (
        tripsView === "table" ? (
          <Grid container mt={4}>
            <TripsTable
              trips={trips}
              actions={["edit", "delete", "toggle", "share", "copy"]}
              setTrips={setTrips}
              columns={columnsForTable}
              callee="home"
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
          </Grid>
        ) : (
          <Grid container mt={4}>
            <GridView
              trips={trips}
              callee="home"
              actions={["delete", "toggle", "share", "copy"]}
              setTrips={setTrips}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              tripsCoverPhotos={tripsCoverPhotos}
              setTripCoverPhotos={setTripCoverPhotos}
            />
          </Grid>
        )
      ) : (
        ""
      )}
    </Grid>
  ) : (
    <Loader />
  );
};
