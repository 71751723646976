import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TripFieldsCollaborators, TripFieldsPublic } from ".";

export const TripShareDetail = ({
  expanded,
  setExpanded,
  sidebarCollapsed,
}) => {
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "share"}
      onChange={handleChange("share")}
      sx={sidebarCollapsed ? { visibility: "hidden" } : {}}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Share</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TripFieldsCollaborators />
        <Divider sx={{ marginTop: 1 }} />
        <TripFieldsPublic />
      </AccordionDetails>
    </Accordion>
  );
};
