import { Grid, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { DataGrid } from "@mui/x-data-grid";
import { CopyTripAttachmentsExplanation } from "../constants";

export const TripFieldsCopy2 = ({
  events,
  rowSelectionModel,
  setRowSelectionModel,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ maxHeight: 400, width: "100%", overflow: "scroll" }}>
          <DataGrid
            rows={events.all}
            columns={[
              {
                field: "title",
                headerName: "Title",
                renderCell: (value) => {
                  const { favorite, title } = value.row;
                  return favorite ? (
                    <>
                      {title}
                      <span role="img" style={{ marginLeft: "10px" }}>
                        <FavoriteIcon />
                      </span>
                    </>
                  ) : (
                    <>{title}</>
                  );
                },
                alignItems: "center",
                flex: 1,
              },
              { field: "start" },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              sorting: {
                sortModel: [{ field: "start", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  start: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableColumnMenu={true}
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <Typography
          component="div"
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontWeight: "500",
            fontSize: "0.875rem",
          }}
        >
          {CopyTripAttachmentsExplanation}
        </Typography>
      </Grid>
    </Grid>
  );
};
