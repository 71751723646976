import { Popover as BootstrapPopover } from "bootstrap";
export let popoverInstance = null;

export const handleEventMouseEnter = (info) => {
  if (popoverInstance) {
    popoverInstance.dispose();
    popoverInstance = null;
  }

  if (info.event._def.extendedProps.description) {
    popoverInstance = new BootstrapPopover(info.el, {
      title: info.event._def.title,
      content: info.event._def.extendedProps.description,
      html: true,
      placement: "top",
      trigger: "hover",
      container: "body",
      template:
        '<div class="popover custom-bs-popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
    });

    popoverInstance.show();
  }
};

export const handleEventMouseLeave = () => {
  if (popoverInstance) {
    popoverInstance.dispose();
    popoverInstance = null;
  }
};
