import { Chip, Tooltip, useTheme } from "@mui/material";

// TO DO refactor so can be shared with LocationChip
export const SuggestedLocationChip = ({
  label,
  tooltipTitle = "",
  icon = null,
  onClick,
  clickable,
}) => {
  const theme = useTheme();

  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        icon={icon}
        label={label}
        variant="outlined"
        onClick={(event) => {
          if (onClick) {
            onClick();
            event.stopPropagation();
          }
        }}
        clickable={clickable}
        sx={{ margin: theme.spacing(0.5) }}
      />
    </Tooltip>
  );
};
