import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useNavigate, useParams } from "react-router-dom";
import { convertToBase64 } from "../../utils/storage_utils";
import { useConfirm } from "material-ui-confirm";
import { Loader } from "../";

export const FollowDialog = ({
  open,
  onClose,
  title,
  items,
  currentUserFollowing,
  currentUserFollowers,
  dialogFollowHandler,
  dialogUnfollowHandler,
  dialogRemoveFollowerHandler,
  loading,
  setLoading,
  setProfileFollowers,
  setProfileFollowing,
}) => {
  const currentUserId = localStorage.getItem("user_id");
  let navigate = useNavigate();
  const handleClose = () => {
    // only allow close if nothing has been added to loading
    if (Object.keys(loading).length === 0) onClose();
  };

  useEffect(() => {
    if (items && items.length === 0) {
      onClose();
    }
  }, [items, onClose]);

  return (
    // TO DO
    // optimize at scale, ex: 1000 followers
    // not practical to call at once
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="paper"
      sx={{ maxHeight: "400px", margin: "auto" }}
    >
      <DialogTitle>{title}</DialogTitle>
      {items ? (
        <>
          <DialogContent>
            <List sx={{ pt: 0 }}>
              {items.length > 0
                ? items.map((item) =>
                    item ? (
                      <ListItem
                        disableGutters
                        key={item.id}
                        // alignItems="start"
                        secondaryAction={
                          item.id === currentUserId ? (
                            ""
                          ) : (
                            <FollowUnfollowButton
                              // iterating over dialog items
                              // nested ternary not ideal but need to differentiate
                              // b/w undefined and false values
                              // if it hasn't executed, return undefined so no button renders
                              // otherwise iterate over current users following response
                              // see if contains current dialog item
                              // if true, isFollowing true
                              isFollowing={
                                currentUserFollowing.executed === true
                                  ? currentUserFollowing.response &&
                                    currentUserFollowing.response.length &&
                                    currentUserFollowing.response.filter(
                                      (f) => f.id === item.id
                                    ).length > 0
                                    ? true
                                    : false
                                  : undefined
                              }
                              isFollower={
                                currentUserFollowers.executed === true
                                  ? currentUserFollowers.response &&
                                    currentUserFollowers.response.length &&
                                    currentUserFollowers.response.filter(
                                      (f) => f.id === item.id
                                    ).length > 0
                                    ? true
                                    : false
                                  : undefined
                              }
                              dialogFollowHandler={dialogFollowHandler}
                              dialogUnfollowHandler={dialogUnfollowHandler}
                              dialogRemoveFollowerHandler={
                                dialogRemoveFollowerHandler
                              }
                              item={item}
                              loading={loading}
                              setLoading={setLoading}
                              title={title}
                            />
                          )
                        }
                        sx={{ paddingRight: 0 }}
                      >
                        <ListItemButton
                          onClick={() => {
                            // only make navigate work if loading is empty
                            if (loading && Object.keys(loading).length === 0) {
                              navigate(`/profile/${item.id}`);
                              // reinitialize
                              setProfileFollowers({
                                executed: false,
                                response: undefined,
                              });
                              setProfileFollowing({
                                executed: false,
                                response: undefined,
                              });
                              handleClose();
                            }
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{ bgcolor: blue[100], color: blue[600] }}
                              src={item.file && convertToBase64(item.file)}
                            >
                              {item.first_name[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            // https://stackoverflow.com/questions/76809844/mui-listitemtext-primary-text-overflow-the-listitems-secondary-action
                            primaryTypographyProps={{
                              paddingRight: 8,
                            }}
                            style={{
                              maxWidth: "fit-content",
                            }}
                            primary={`${item.first_name} ${item.last_name}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </List>
          </DialogContent>
          <DialogActions />
        </>
      ) : (
        <Loader
          styleOverrides={{
            minHeight: "200px !important",
            minWidth: "285px !important",
          }}
        />
      )}
    </Dialog>
  );
};

FollowDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const FollowUnfollowButton = ({
  isFollowing,
  isFollower,
  dialogFollowHandler,
  dialogUnfollowHandler,
  dialogRemoveFollowerHandler,
  item,
  loading,
  setLoading,
  title,
}) => {
  const confirm = useConfirm();
  const { user_id } = useParams();

  // only show removeFollower for ownProfile and Followers
  if (typeof isFollower === "undefined" && typeof isFollowing === "undefined") {
    return "";
  } else if (
    title === "Followers" &&
    user_id === localStorage.getItem("user_id")
  ) {
    return (
      <Button
        onClick={(e) => {
          const nameOrEmail =
            item.first_name && item.last_name
              ? `${item.first_name} ${item.last_name}`
              : `${item.email}`;
          e.stopPropagation();
          confirm({
            title: "Confirm",
            description: `Remove ${nameOrEmail} as a follower?`,
            confirmationButtonProps: { variant: "contained" },
          })
            .then(() => {
              dialogRemoveFollowerHandler(item);
              setLoading({ ...loading, [item.id]: true });
            })
            .catch((e) => {
              console.log({ e });
            });
        }}
        disabled={loading && Object.hasOwn(loading, item.id) ? true : false}
        size="small"
      >
        Remove
      </Button>
    );
  } else if (isFollowing) {
    return (
      <Button
        id="following-button"
        variant="contained"
        onClick={(e) => {
          const nameOrEmail =
            item.first_name && item.last_name
              ? `${item.first_name} ${item.last_name}`
              : `${item.email}`;
          e.stopPropagation();
          confirm({
            title: "Confirm",
            description: `Unfollow ${nameOrEmail}?`,
            confirmationButtonProps: { variant: "contained" },
          })
            .then(() => {
              dialogUnfollowHandler(item);
              setLoading({ ...loading, [item.id]: true });
            })
            .catch((e) => {
              console.log({ e });
            });
        }}
        disabled={loading && Object.hasOwn(loading, item.id) ? true : false}
        size="small"
      >
        Following
      </Button>
    );
  } else if (typeof isFollowing !== "undefined") {
    return (
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          dialogFollowHandler(item);
          setLoading({ ...loading, [item.id]: true });
        }}
        disabled={loading && Object.hasOwn(loading, item.id) ? true : false}
        size="small"
      >
        Follow
      </Button>
    );
  }
};
