import { useContext } from "react";
import { AppContext } from "../contexts";
import { Grid, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import { useConfirm } from "material-ui-confirm";
import { UploadButton } from ".";
import { allowedEventFileInfo } from "../constants";
import "react-pdf/dist/esm/Page/TextLayer.css";

export const EventFileButton = ({
  deleteHandler,
  uploadHandler,
  loading,
  setLoading,
  setAlert,
  setFileDisplayBackdropOpen,
}) => {
  const { eventFileStr, eventFileExtension } = useContext(AppContext);
  const { fileExtensions } = allowedEventFileInfo;

  if (
    eventFileStr === "File not found" ||
    typeof eventFileStr === "undefined"
  ) {
    return (
      <Grid item xs={12}>
        <UploadButton
          id="event-input-file-upload"
          selectFileHandler={uploadHandler}
          allowedFileInfo={allowedEventFileInfo}
          loading={loading}
          setLoading={setLoading}
          setAlert={setAlert}
          label="Add"
          icon={<AttachFileIcon />}
        />
      </Grid>
    );
  } else if (fileExtensions.includes(eventFileExtension)) {
    return (
      <PdfOrImageFileIconsDisplay
        eventFileExtension={eventFileExtension}
        eventFileStr={eventFileStr}
        deleteHandler={deleteHandler}
        setFileDisplayBackdropOpen={setFileDisplayBackdropOpen}
      />
    );
  }
};

const PdfOrImageFileIconsDisplay = ({
  eventFileExtension,
  deleteHandler,
  setFileDisplayBackdropOpen,
}) => {
  const confirm = useConfirm();

  return (
    <Grid item id="file-icons-container">
      <Tooltip title="View attachment">
        <IconButton
          aria-label="view"
          onClick={() => {
            setFileDisplayBackdropOpen(true);
          }}
        >
          {eventFileExtension === "pdf" ? <PictureAsPdfIcon /> : <ImageIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete attachment">
        <IconButton
          aria-label="delete"
          onClick={() => {
            confirm({
              title: "Confirm",
              description: "This action is permanent!",
              confirmationButtonProps: { variant: "contained" },
            })
              .then(() => {
                deleteHandler();
              })
              .catch(() => {});
          }}
          id="icon-button-event-file-delete"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};
