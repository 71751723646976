import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Alert,
  useTheme,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveUserInfo, setLocalStorageItem } from "../../utils/user_utils";
import {
  uploadFile,
  convertToBase64,
  deleteFile,
  resizeBase64Img,
} from "../../utils/storage_utils";
import { CloseModal } from "./";
import { useConfirm } from "material-ui-confirm";
import { allowedAvatarFileInfo } from "../../constants";
import { UploadAvatar } from "../";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CropPhotoDialog } from "./";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const AccountDialog = ({
  open,
  handleClose,
  avatarFileStr,
  setAvatarFileStr,
  setAvatarFileExtension,
}) => {
  const userId = localStorage.getItem("user_id");
  const firstName = localStorage.getItem("first_name");
  const lastName = localStorage.getItem("last_name");
  const [draftFirstName, setDraftFirstName] = useState(firstName || "");
  const [draftLastName, setDraftLastName] = useState(lastName || "");
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [imgSrc, setImgSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [cropPhotoDialogOpen, setCropPhotoDialogOpen] = useState(false);

  const confirm = useConfirm();

  useEffect(() => {
    setDraftFirstName(firstName);
    setDraftLastName(lastName);
  }, [firstName, lastName]);

  const UploadHandler = ({ file }) => {
    const uploadFailureTimeout = setTimeout(() => {
      setAlert({
        severity: "error",
        message: "Something went wrong. Try again.",
      });
      setLoading(false);
    }, 30000);
    const id = userId ? userId : null;
    cropPhotoDialogOpen && setCropPhotoDialogOpen(false);
    uploadFile({
      file,
      id,
      bucket: "avatars",
    }).then((response) => {
      if (response) {
        if (response.status === 500) {
          setAlert({
            severity: "error",
            message: response.message,
          });
        } else if (response.status === 200) {
          setAvatarFileExtension(response.extension);
          // additional logic for converting avatar to smaller sized file
          // so it can be stored in local storage, to be used across pages
          const base64Str = convertToBase64(response);
          resizeBase64Img(base64Str, 250, 250).then((resizedBase64Str) => {
            setAvatarFileStr(resizedBase64Str);
            setLocalStorageItem({
              key: "current_user_avatar_url",
              value: resizedBase64Str,
            });
          });
        }
        setLoading(false);
        clearTimeout(uploadFailureTimeout);
      }
    });
  };

  const DeleteHandler = () => {
    setLoading(true);
    const id = userId ? userId : null;
    deleteFile({ id, bucket: "avatars" }).then((response) => {
      if (response) {
        setAvatarFileStr("File not found"); //short circuit
        setAvatarFileExtension(undefined);
        setLoading(false);
        localStorage.removeItem("current_user_avatar_url");
      }
    });
  };

  const SelectFileHandler = ({ file }) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setCropPhotoDialogOpen(true);
        setLoading(false);
        setSelectedFile({ name: file.name, type: file.type });
      });
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // every time opened, reinitialize state
    setAlert(false);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        PaperProps={{
          component: "form",
        }}
        // https://stackoverflow.com/questions/61439557/how-to-change-the-position-of-material-uis-dialog
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
      >
        <AccountDialogTitle handleClose={handleClose} loading={loading} />
        <DialogContent>
          {alert ? (
            <Grid item xs={12} mb={2}>
              <Alert severity={alert.severity} onClose={() => setAlert(false)}>
                {alert.message}
              </Alert>
            </Grid>
          ) : (
            ""
          )}

          <Grid container>
            <Grid item xs={12}>
              <TextField
                label="First name"
                fullWidth={true}
                onChange={(e) => {
                  setDraftFirstName(e.target.value);
                }}
                required
                value={draftFirstName}
                autoFocus
                disabled={loading ? true : false}
              ></TextField>
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                label="Last name"
                fullWidth={true}
                onChange={(e) => {
                  setDraftLastName(e.target.value);
                }}
                required
                value={draftLastName}
                disabled={loading ? true : false}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6} mt={1}>
              {localStorage.getItem("current_user_avatar_url") ||
              (avatarFileStr && avatarFileStr !== "File not found") ? (
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <Avatar
                      component="label"
                      variant="contained"
                      src={localStorage.getItem("current_user_avatar_url")}
                    >
                      {/* make sure firstName exists, 
                    race condition where it can be null upon signout */}
                      {firstName && firstName[0]}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Delete your avatar">
                      <span>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            confirm({
                              title: "Confirm",
                              description: "This action is permanent!",
                              confirmationButtonProps: { variant: "contained" },
                            })
                              .then(() => {
                                DeleteHandler();
                              })
                              .catch(() => {});
                          }}
                          disabled={loading ? true : false}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                <UploadAvatar
                  selectFileHandler={SelectFileHandler}
                  allowedFileInfo={allowedAvatarFileInfo}
                  loading={loading}
                  setLoading={setLoading}
                  setAlert={setAlert}
                  // check exists, see comment on 199
                  label={firstName && firstName[0]}
                  icon={
                    <AddCircleIcon
                      sx={{
                        height: "22px",
                        width: "22px",
                      }}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={
              loading || !(draftFirstName && draftLastName)
                ? "Fill out all fields to update info"
                : ""
            }
          >
            <span>
              <Button
                onClick={() =>
                  saveUserInfo({
                    firstName: draftFirstName,
                    lastName: draftLastName,
                    userId,
                  }).then((user) => {
                    handleClose();
                  })
                }
                variant="contained"
                disabled={
                  loading || !(draftFirstName && draftLastName) ? true : false
                }
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>

      {cropPhotoDialogOpen && imgSrc ? (
        <CropPhotoDialog
          imgSrc={imgSrc}
          open={cropPhotoDialogOpen}
          handleClose={() => setCropPhotoDialogOpen(false)}
          selectedFile={selectedFile}
          uploadHandler={UploadHandler}
          loading={loading}
          setLoading={setLoading}
          circularCrop={true}
          aspectRatio={1}
        />
      ) : (
        ""
      )}
    </>
  );
};

const AccountDialogTitle = ({ handleClose, loading }) => {
  return (
    <Grid
      container
      sx={{
        alignItems: "center",
      }}
    >
      <Grid item>
        <DialogTitle>Update Info </DialogTitle>
      </Grid>
      {handleClose ? (
        <CloseModal
          handleClose={() => {
            handleClose();
          }}
          loading={loading}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};
