/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e34f77f5-ddaa-49e6-88d4-4fa59fbc8a23",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WCkB8owLG",
    "aws_user_pools_web_client_id": "5dcosmrmsfsqmpmo713a77dfma",
    "oauth": {
        "domain": "trippyclient3da5b26a-3da5b26a-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.trippyapp.xyz/,https://trippy-be-pr-200.onrender.com/,https://trippy-be-pr-121.onrender.com/home/,http://localhost:3000/home/",
        "redirectSignOut": "http://localhost:3000/,https://www.trippyapp.xyz/,https://trippy-be-pr-200.onrender.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
